import type { RigCardFactDto, RigFleetPerformanceCardUserLensDto } from "apis/oag";
import { PivotOrderType, PivotType } from "apis/oag";
import dayjs from "dayjs";
import { useOperators } from "hooks/useOperators";
import { useRigs } from "hooks/useRigs";
import { useWellShortInfo } from "hooks/useWellShortInfo";
import { isObject } from "lodash";
import {
  NetTimeType,
  ScoreType,
  SelectedMetric,
} from "pages/AllRigs/components/LeaderboardSection/LeaderboardMetricSelectionContext";
import {
  BOTTOM_AXIS_HEIGHT_REGULAR_LABELS,
  drillingProductivityTypes,
  OPERATOR_AXIS_ADDED_HEIGHT,
  RIG_AXIS_ADDED_HEIGHT,
  WELL_AXIS_ADDED_HEIGHT
} from "pages/AllRigs/components/LeaderboardSection/utils";
import { RigCardLayoutType } from "pages/AllRigs/utils";
import { useCallback } from "react";
import { match, P } from "ts-pattern";

import type { FactWithLabel } from "./interfaces";


export type DisplayedPivotTypes =
  | typeof PivotType.Well
  | typeof PivotType.Rig
  | typeof PivotType.Operator
  | typeof PivotType.Month
  | typeof PivotType.Quarter;

export function useGroupAndSort() {
  const { data: wellInfo } = useWellShortInfo();
  const { data: rigsData } = useRigs();
  const { data: operators } = useOperators();

  const getName = useCallback((label: string, rigPivot: PivotType) => {
    return match(rigPivot)
      .with(PivotType.Well, () => {
        const well = wellInfo?.byId[+label];
        return well?.name ?? label;
      }
      ).with(PivotType.Rig, () => {
        const rig = rigsData?.byId[+label];
        return rig?.shortName.split(" ")[1] ?? label;
      }).with(PivotType.Operator, () => {
        const operator = operators?.byId[+label];
        return operator?.name ?? label;
      }).otherwise(() => label);
  }, [operators?.byId, rigsData?.byId, wellInfo?.byId]);
  const groupAndSortFacts = useCallback((
    {
      selectedMetric,
      selectedNetTime,
      selectedScore,
      allFacts,
      order,
      rigPivot
    }: {
      selectedMetric: SelectedMetric;
      selectedNetTime?: NetTimeType;
      selectedScore?: ScoreType;
      allFacts: RigCardFactDto[];
      order: PivotOrderType
      rigPivot: PivotType
    }
  ) => {
    let unsortedFacts: Array<FactWithLabel> = [];
    if (selectedMetric === SelectedMetric.NetTimes) {
      if (selectedNetTime === NetTimeType.Target) {
        unsortedFacts = allFacts
          .filter((fact) => isObject(fact.targetDeltaTime))
          .map((fact) => ({ ...fact.targetDeltaTime, label: fact.label }));
      } else if (selectedNetTime === NetTimeType.LostTime) {
        unsortedFacts = allFacts
          .filter((fact) => isObject(fact.invisibleLostTime))
          .map((fact) => ({ ...fact.invisibleLostTime, label: fact.label }));
      } else if (selectedNetTime === NetTimeType.TimeSaved) {
        unsortedFacts = allFacts
          .filter((fact) => isObject(fact.savedTime))
          .map((fact) => ({ ...fact.savedTime, label: fact.label }));
      }
    } else if (selectedMetric === SelectedMetric.Scores) {
      if (selectedScore === ScoreType.OperatorScore) {
        unsortedFacts = allFacts
          .filter((fact) => isObject(fact.operatorScore))
          .map((fact) => ({ ...fact.operatorScore, label: fact.label }));
      } else if (selectedScore === ScoreType.ImprovementScore) {
        unsortedFacts = allFacts
          .filter((fact) => isObject(fact.improvementScore))
          .map((fact) => ({ ...fact.improvementScore, label: fact.label }));
      } else if (selectedScore === ScoreType.RigScore) {
        unsortedFacts = allFacts
          .filter((fact) => isObject(fact.rigScore))
          .map((fact) => ({ ...fact.rigScore, label: fact.label }));
      }
    } else {
      unsortedFacts = allFacts
        .filter((fact) => isObject(fact.kpiValue))
        .map((fact) => ({ ...fact.kpiValue, label: fact.label }));
    }

    return unsortedFacts.toSorted((a, b) => {
      if (rigPivot === PivotType.Quarter) {
        const [yearA, quarterA] = a.label.split("-Q");
        const [yearB, quarterB] = b.label.split("-Q");
        if (
          order === PivotOrderType.LastUpdateAscending
        )
          return yearA.localeCompare(yearB) || +quarterA - +quarterB;
        if (
          order === PivotOrderType.LastUpdateDescending
        )
          return yearB.localeCompare(yearA) || +quarterB - +quarterA;
      }
      if (rigPivot === PivotType.Month) {
        const [yearA, monthA] = a.label.split("-");
        const [yearB, monthB] = b.label.split("-");
        if (
          order === PivotOrderType.LastUpdateAscending
        )
          return yearA.localeCompare(yearB) || +monthA - +monthB;
        if (
          order === PivotOrderType.LastUpdateDescending
        )
          return yearB.localeCompare(yearA) || +monthB - +monthA;
      }

      if (rigPivot === PivotType.Well) {
        if (
          (
            [
              PivotOrderType.WellLastActivityDateAscending,
              PivotOrderType.WellLastActivityDateDescending,
              PivotOrderType.WellSpudDateAscending,
              PivotOrderType.WellSpudDateDescending
            ] as PivotOrderType[])
            .includes(order)) {
          const aWell = wellInfo?.byId[+a.label];
          const bWell = wellInfo?.byId[+b.label];
          if (!aWell || !bWell) return 0;
          return match(order)
            .with(PivotOrderType.WellLastActivityDateAscending, () => {
              return dayjs(aWell?.lastWellFactUpdateAt.utc).diff(dayjs(bWell?.lastWellFactUpdateAt.utc));
            }).with(PivotOrderType.WellLastActivityDateDescending, () => {
              return dayjs(bWell?.lastWellFactUpdateAt.utc).diff(dayjs(aWell?.lastWellFactUpdateAt.utc));
            }).with(PivotOrderType.WellSpudDateAscending, () => {
              return dayjs(aWell?.spudDateTime.utc).diff(dayjs(bWell?.spudDateTime.utc));
            }).with(PivotOrderType.WellSpudDateDescending, () => {
              return dayjs(bWell?.spudDateTime.utc).diff(dayjs(aWell?.spudDateTime.utc));
            }).otherwise(() => 0);
        }
      }

      if (rigPivot === PivotType.Rig) {
        if (order === PivotOrderType.PivotKeyDescending)
          return +getName(b.label, rigPivot) - (+getName(a.label, rigPivot));
        if (order === PivotOrderType.PivotKeyAscending)
          return +getName(a.label, rigPivot) - (+getName(b.label, rigPivot));
      }

      switch (order) {
        case PivotOrderType.PivotKeyDescending:
          return getName(b.label, rigPivot).localeCompare(getName(a.label, rigPivot));
        case PivotOrderType.PivotKeyAscending:
          return getName(a.label, rigPivot).localeCompare(getName(b.label, rigPivot));
        case PivotOrderType.PivotValueAscending:
          return (b.rank ?? 0) - (a.rank ?? 0);
        case PivotOrderType.PivotValueDescending:
          return (a.rank ?? 0) - (b.rank ?? 0);
        default:
          return 0;
      }
    }).map((item, index) => {
      let currentOrderByPivotSort = index;
      if (([PivotOrderType.PivotValueAscending, PivotOrderType.PivotValueDescending] as PivotOrderType[]).includes(order))
        currentOrderByPivotSort = item.rank;
      else {
        if (order.toLocaleLowerCase().includes("ascending"))
          currentOrderByPivotSort = index + 1;
        else
          currentOrderByPivotSort = unsortedFacts.length - index;
      }
      return { ...item, order: currentOrderByPivotSort };
    });
  }, [getName, wellInfo?.byId]);
  return { groupAndSortFacts };
}

export function isDrillingProductivityLens(lens: RigFleetPerformanceCardUserLensDto): boolean {
  return drillingProductivityTypes.includes(lens.cardKpiType);
}

export const MATCH_WELLS_LABEL_AS_RANKS = {
  pivotType: P.union(PivotType.Well, PivotType.Operator),
  isExpanded: false,
  layoutType: RigCardLayoutType.Split,
};

export function getBottomAxisHeightByCriteria({
  pivotType,
  isExpanded,
  layoutType,
}: {
  pivotType: DisplayedPivotTypes;
  isExpanded: boolean;
  layoutType: RigCardLayoutType;
}) {
  const addedHeight = match({ pivotType, isExpanded, layoutType })
    .with(MATCH_WELLS_LABEL_AS_RANKS, () => 0)
    .with(
      { pivotType: PivotType.Well },
      ({ layoutType, isExpanded }) => layoutType === RigCardLayoutType.BarsOnly || isExpanded,
      () => WELL_AXIS_ADDED_HEIGHT,
    )
    .with(
      { pivotType: PivotType.Operator },
      ({ layoutType, isExpanded }) => layoutType === RigCardLayoutType.BarsOnly || isExpanded,
      () => OPERATOR_AXIS_ADDED_HEIGHT,
    )
    .with({ pivotType: PivotType.Rig }, () => RIG_AXIS_ADDED_HEIGHT)
    .otherwise(() => 0);

  return { total: BOTTOM_AXIS_HEIGHT_REGULAR_LABELS + addedHeight, added: addedHeight };
}
