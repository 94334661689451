import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { WellStatusType } from "apis/oag";
import { RigsApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateOnlyDto } from "utils/helper";
import type { PDRigsActiveWellsQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const rigs = new RigsApi(apiConfig);

const useQueryParameters = (
  rigIds: number[],
  options?: Omit<UseQueryOptions<Array<number>>, "queryKey" | "queryFn"> & {
    isFilteringIgnored?: boolean;
  },
) => {
  const filterState = useAppSelector((state) => state.allRigs.filters);
  const rigPeriodState = useAppSelector((state) => state.allRigs.period);

  const requestBody = {
    allRigsQueryDto: {
      rigIds,
      from: rigPeriodState.startDate ? dateToDateOnlyDto(rigPeriodState.startDate) : defaultDateDto.from,
      to: rigPeriodState.endDate ? dateToDateOnlyDto(rigPeriodState.endDate) : defaultDateDto.to,
      includeFullWellFacts: false,
      selectedFilters: {
        directionalIntervalIds: null,
        includeFlatTime: true,
        includeSlidingTime: true,
        includeRotatingTime: true,
        includeNullHoleDepth: true,
        holeSizeIds: null,
        sectionIds: null,
        includeAlphaRigs: true,
        includeNoneAlphaRigs: true,
        selectedWellStatusTypes: options?.isFilteringIgnored
          ? null
          : (filterState?.wellStatus.map((ws) => ws.id) as WellStatusType[]),
      },
    },
  };
  const RigsWellsQueryKey: PDRigsActiveWellsQueryKey = {
    uid: RequestUID.rigsWells,
    type: PDQueryType.RIGS_ACTIVE_WELLS,
    isFilteringIgnored: !!options?.isFilteringIgnored,
    requestBody,
  };
  return {
    queryKey: [RigsWellsQueryKey],
    queryFn: async () => {
      return await rigs.apiRigsWellIdsPut(requestBody);
    },
    ...options,
  };
};

export function useRigActiveToWells(
  rigIds: number[],
  options?: Omit<UseQueryOptions<Array<number>>, "queryKey" | "queryFn"> & {
    isFilteringIgnored?: boolean;
  },
) {
  const queryParameters = useQueryParameters(rigIds, options);
  return useQuery<Array<number>>(queryParameters);
}
