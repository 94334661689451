/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { OperationCategoryType } from './OperationCategoryType';
import {
    OperationCategoryTypeFromJSON,
    OperationCategoryTypeFromJSONTyped,
    OperationCategoryTypeToJSON,
} from './OperationCategoryType';

/**
 * 
 * @export
 * @interface OperationClassDto
 */
export interface OperationClassDto {
    /**
     * 
     * @type {number}
     * @memberof OperationClassDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof OperationClassDto
     */
    externalId?: string | null;
    /**
     * 
     * @type {string}
     * @memberof OperationClassDto
     */
    name?: string | null;
    /**
     * 
     * @type {number}
     * @memberof OperationClassDto
     */
    position: number;
    /**
     * 
     * @type {OperationCategoryType}
     * @memberof OperationClassDto
     */
    category: OperationCategoryType;
}



/**
 * Check if a given object implements the OperationClassDto interface.
 */
export function instanceOfOperationClassDto(value: object): value is OperationClassDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('position' in value) || value['position'] === undefined) return false;
    if (!('category' in value) || value['category'] === undefined) return false;
    return true;
}

export function OperationClassDtoFromJSON(json: any): OperationClassDto {
    return OperationClassDtoFromJSONTyped(json, false);
}

export function OperationClassDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): OperationClassDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'externalId': json['ExternalId'] == null ? undefined : json['ExternalId'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'position': json['Position'],
        'category': OperationCategoryTypeFromJSON(json['Category']),
    };
}

export function OperationClassDtoToJSON(value?: OperationClassDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'ExternalId': value['externalId'],
        'Name': value['name'],
        'Position': value['position'],
        'Category': OperationCategoryTypeToJSON(value['category']),
    };
}

