import { convertLength } from "@turf/helpers";
import ButtonGroup from "antd/lib/button/button-group";
import { type AllWellsListDto, WellStatusType } from "apis/oag";
import type { RangeType } from "atoms/DatePicker";
import { DatePicker } from "atoms/DatePicker";
import { AutoComplete, AutoCompleteDropDown, Button } from "atoms/Form";
import { Title } from "atoms/Typography";
import { getTitleRealTimeIndicator } from "components/DetailsTopBar/utils";
import { AllFilters } from "components/Filters/AllFilters";
import { FilterTags } from "components/Filters/FilterTags";
import { useFilterTags } from "components/Filters/useFilterTags";
import { PDComponent } from "components/PDComponents";
import type { IOption } from "components/PDComponents/Search/utils";
import { RealTimeDataEnum, RealTimeIndicator } from "components/RealTimeIndicator";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useGetBasins } from "hooks/useBasins";
import { useCurrentUser } from "hooks/useCurrentUser";
import { useDashboardType } from "hooks/useDashboardType";
import { useFormations } from "hooks/useFormations";
import { useOperators } from "hooks/useOperators";
import { useOperatorsActiveRigs } from "hooks/useOperatorsActiveRigs";
import { useRigs } from "hooks/useRigs";
import { useRigsActiveOperators } from "hooks/useRigsActiveOperators";
import { useRigSort } from "hooks/useRigSort";
import { useWellQuickSearch } from "hooks/useWellQuickSearch";
import { useWellSummaries } from "hooks/useWellSummaries";
import { AreaSelectionType, useWellsMapViewContext } from "pages/AllWells/components/MapView/useWellsMapView";
import { useFilteredAllWells } from "pages/AllWells/useFilteredAllWells";
import type { ValueType } from "rc-input-number/lib/utils/MiniDecimal";
import type { FC } from "react";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Link } from "react-router-dom";
import type { IFilterOptions } from "reducers/allRigsReducer";
import { useAppDispatch } from "reducers/store";
import { useAppSelector } from "reducers/store";
import { IUnitSystem } from "reducers/types";
import { Track } from "services/Mixpanel";
import styled from "styled-components";
import colors from "utils/colors";
import { defaultDateDto } from "utils/common";
import { Col, Popover, Row, Space, Tooltip } from "utils/componentLibrary";
import { PdSpaceItem } from "utils/componentLibrary/Space";
import { FilterType } from "utils/enums";
import { highPrivilegeRoles } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import {
  FilterTagsContainer,
  HeaderContainer,
  PaddedSpace,
  RightContainer,
  StyledButton,
  StyledCircleButton,
  StyledCol,
  StyledInputNumber,
  StyledRadiusButton,
  StyledTitle,
} from "./StyledComponents";

export const StyledSpaceForItems = styled(Space)`
  width: 100%;

  ${PdSpaceItem} {
    padding: 12px;
    margin: 0 !important;
    border-bottom: 1px solid ${colors.separation_line};
  }

  .ant-checkbox-wrapper {
    display: flex;
    align-items: center;

    .ant-typography {
      line-height: 1;
    }
  }
`;

const BasinName = styled.div`
  height: 36px;
  width: 200px;
  display: grid;
  place-items: center;
  padding: 0px 6px;
  border: 1px solid ${({ theme }) => (theme.isDark ? colors.limo_scene : colors.widget_line)};
  border-radius: 0px 5px 5px 0px;
  background-color: ${({ theme }) => theme.themeStyle.colors.alt_secondary_bg};
`;

export const StyledSpace = styled(Space)`
  margin: -12px -16px;
  border-radius: 4px;
  overflow: hidden;
  background: ${colors.actions_bg};
  min-width: 250px;
`;

const AllWellsHeader: FC<{
  setHeaderHeight: React.Dispatch<React.SetStateAction<number>>;
  wellFilters: IFilterOptions | null;
}> = ({ setHeaderHeight, wellFilters }) => {
  const { isEvergreen } = useDashboardType();
  const { data: basins } = useGetBasins();
  const { data: formations } = useFormations();
  const basinNames = useMemo(() => basins?.map((basin) => basin.name) ?? [], [basins]);

  // TODO ADD MP Tracking
  const dispatch = useAppDispatch();

  const operators = useOperators();
  const rigs = useRigs();

  const [lastSelectedFilter, setLastSelectedFilter] = useState<FilterType | undefined>();
  const prevWellSummaries = useRef<AllWellsListDto | null>(null);

  const { data: userData } = useCurrentUser();
  const isInDemoMode = userData?.isInDemoMode ?? false;

  const reduxState = isEvergreen ? "evergreenWells" : "allWells";
  const trackingTitle = isEvergreen ? "Evergreen Wells" : "All Wells";

  const statusState = useAppSelector((state) => state[reduxState].status);
  const searchState = useAppSelector((state) => state[reduxState].search);
  const startDateState = useAppSelector((state) => state[reduxState].startDate);
  const endDateState = useAppSelector((state) => state[reduxState].endDate);
  const _operatorsState = useAppSelector((state) => state[reduxState].operators);
  const _rigIdsState = useAppSelector((state) => state[reduxState].rigIds);
  const operatorsState = useMemo(() => {
    return _operatorsState?.length ? _operatorsState : operators?.data?.list.map((e) => e.id);
  }, [_operatorsState, operators?.data?.list]);
  const rigIdsState = useMemo(() => {
    return _rigIdsState?.length ? _rigIdsState : rigs?.data?.list.map((e) => e.id);
  }, [_rigIdsState, rigs?.data?.list]);

  const formationIdsState = useAppSelector((state) => state[reduxState].formationIds);

  const containerRef = useRef<HTMLDivElement>(null);

  useEffect(() => {
    const observer = new ResizeObserver((entries) => {
      setHeaderHeight(entries[0].contentRect.height);
    });
    containerRef.current && observer.observe(containerRef.current);
    return () => {
      if (containerRef.current) {
        observer.unobserve(containerRef.current);
      }
    };
  }, [setHeaderHeight]);

  const {
    areaText,
    handleOnCircleAreaPress,
    handleOnPolygonAreaPress,
    handleOnTrashPress,
    areaSelectionType,
    radius,
    setRadius,
    radiusMaxBound,
    basinName,
    setBasinName,
  } = useWellsMapViewContext();
  const [showBasinPopup, setShowBasinPopup] = useState(false);
  const [wellSearchPopup, setWellSearchPopup] = useState(false);

  const [realTimeDataState, setRealtimeData] = useStateQuery<RealTimeDataEnum>(
    URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.ACTIVE,
  );
  useEffect(() => {
    if (realTimeDataState !== RealTimeDataEnum.DISABLED) setRealtimeData(RealTimeDataEnum.ACTIVE);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const rigIds = useOperatorsActiveRigs(operatorsState, isEvergreen);
  const prevRigIds = useRef<number[] | undefined>(undefined);
  const initialRigIds = useRef<number[] | undefined>(undefined);

  useEffect(() => {
    if (!initialRigIds.current) {
      initialRigIds.current = rigIds.data;
    }
  }, [rigIds.data]);

  const currentUom = useAppSelector((state) => state.global.unit);

  const operatorIds = useRigsActiveOperators(rigIdsState, isEvergreen);
  const prevOperatorIds = useRef<number[] | undefined>(undefined);

  const formationIds = formations?.list.map((formation) => formation.id);
  const prevFormationIds = useRef<number[] | undefined>(undefined);

  const wellSummaries = useWellSummaries(false);

  const [quickSearchValue, setQuickSearchValue] = useState<string>(searchState);
  const [quickSearchOptions, setQuickSearchOptions] = useState<{ value: string }[]>([]);

  const [showOperatorPopup, setShowOperatorPopup] = useState(false);
  const [showRigPopup, setShowRigPopup] = useState(false);
  const [showFormationPopup, setShowFormationPopup] = useState(false);
  const [showStatusPopup, setShowStatusPopup] = useState(false);

  const updateStatuses = (f: WellStatusType[]) => {
    setShowStatusPopup(false);
    Track.interact(`${trackingTitle} - State`, {
      Action: "Update",
      "Current State": f,
    });
    dispatch({
      type: isEvergreen ? "EVERGREEN_WELLS_SET_STATUSES" : "ALL_WELLS_SET_STATUSES",
      payload: {
        status: f,
      },
    });
    return true;
  };

  const quickSearch = useWellQuickSearch({ query: quickSearchValue, nonPendingOnly: false });

  useEffect(() => {
    const list =
      lastSelectedFilter === FilterType.Well && prevWellSummaries.current
        ? prevWellSummaries.current.wells
        : wellSummaries.data?.wells;

    const set = new Set(list?.map((well) => well.id) ?? []);

    setQuickSearchOptions(
      quickSearch.data?.filter((well) => set.has(well.id)).map((well) => ({ value: well.name || "" })) ?? [],
    );
  }, [lastSelectedFilter, quickSearch.data, wellSummaries.data?.wells]);

  const sortByRigs = useRigSort();
  const rigOptions = useMemo(() => {
    const list = lastSelectedFilter === FilterType.Rig && prevRigIds.current ? prevRigIds.current : rigIds.data;

    const set = new Set(list ?? []);
    if (rigs.data?.byId)
      return Array.from(set)
        .sort(sortByRigs)
        .map((rigId) => ({
          id: rigId,
          name: (rigs.data?.byId[rigId]?.shortName || "").toString(),
        }));
    else return [];
  }, [lastSelectedFilter, rigIds.data, rigs.data?.byId, sortByRigs]);

  const operatorOptions = useMemo(() => {
    const list =
      lastSelectedFilter === FilterType.Operator && prevOperatorIds.current
        ? prevOperatorIds.current
        : operatorIds.data;

    const set = new Set(list ?? []);

    if (operators.data?.byId) return Array.from(set).map((operatorId) => operators.data?.byId[operatorId]);
    else return [];
  }, [lastSelectedFilter, operatorIds.data, operators.data?.byId]);

  const updatePeriod = useCallback(
    (period: RangeType) => {
      Track.interact(`${trackingTitle} - Time Range`, {
        "Start Date": period.startDate?.toLocaleDateString?.() ?? "",
        "End Date": period.endDate?.toLocaleDateString?.() ?? "",
      });
      dispatch({
        type: isEvergreen ? "EVERGREEN_WELLS_SET_TIMERANGE" : "ALL_WELLS_SET_TIMERANGE",
        payload: {
          startDate: period.startDate || defaultDateDto.from.utc,
          endDate: period.endDate || defaultDateDto.to.utc,
        },
      });
      return true;
    },
    [trackingTitle, dispatch, isEvergreen],
  );

  const updateSearch = (e: string) => {
    Track.interact(`${trackingTitle} - Search`, {
      "Searched Well": e,
    });
    dispatch({
      type: isEvergreen ? "EVERGREEN_WELLS_SET_SEARCH" : "ALL_WELLS_SET_SEARCH",
      payload: {
        search: e,
      },
    });
    return true;
  };

  const updateOperators = useCallback(
    (operatorIds: number[] | null) => {
      dispatch({
        type: isEvergreen ? "EVERGREEN_WELLS_SET_OPERATORS" : "ALL_WELLS_SET_OPERATORS",
        payload: {
          operators: operatorIds?.length ? operatorIds.slice().sort((a, b) => a - b) : null,
        },
      });
      return true;
    },
    [dispatch, isEvergreen],
  );

  const updateRigs = useCallback(
    (rigIds: number[] | null) => {
      dispatch({
        type: isEvergreen ? "EVERGREEN_WELLS_SET_RIGS" : "ALL_WELLS_SET_RIGS",
        payload: {
          rigIds: rigIds?.length ? rigIds.slice().sort((a, b) => a - b) : null,
        },
      });
      return true;
    },
    [dispatch, isEvergreen],
  );

  const updateFormations = useCallback(
    (formationIds: number[] | null) => {
      dispatch({
        type: isEvergreen ? "EVERGREEN_WELLS_SET_FORMATIONS" : "ALL_WELLS_SET_FORMATIONS",
        payload: {
          formationIds: formationIds?.length ? formationIds.slice().sort((a, b) => a - b) : null,
        },
      });
      return true;
    },
    [dispatch, isEvergreen],
  );

  const setFilters = useCallback(
    (localFilters: IFilterOptions | null) => {
      dispatch({ type: isEvergreen ? "EVERGREEN_WELLS_SET_FILTERS" : "ALL_WELLS_SET_FILTERS", payload: localFilters });
    },
    [dispatch, isEvergreen],
  );

  const { tags } = useFilterTags({
    updateOperators,
    updateRigs,
    updateFormations,
    setFilters,
    updateStatuses,
    wellStatusState: statusState,
    operatorsState,
    rigIdsState,
    formationIdsState,
    filters: wellFilters,
  });

  useEffect(() => {
    if ((rigIds.data?.length || 0) > 0 && lastSelectedFilter === FilterType.Operator) {
      const set = new Set(rigIds.data);
      const filteredRigs = rigIdsState?.filter?.((id) => set.has(id)) ?? [];

      // Do not update if all selected
      if (filteredRigs.length && rigIdsState !== null && filteredRigs.length !== rigIdsState?.length) {
        updateRigs(filteredRigs);
      }
    }
  }, [lastSelectedFilter, rigIds.data, rigIdsState, updateRigs]);

  useEffect(() => {
    if ((operatorIds.data?.length || 0) > 0 && lastSelectedFilter === FilterType.Rig) {
      const set = new Set(operatorIds.data);
      const filteredRigs = operatorsState?.filter((id) => set.has(id));

      if (filteredRigs && filteredRigs.length !== operatorsState?.length) {
        updateOperators(filteredRigs);
      }
    }
  }, [lastSelectedFilter, operatorIds.data, operatorsState, updateOperators]);

  const resetFilters = () => {
    dispatch({
      type: isEvergreen ? "RESET_EVERGREEN_ALL_WELLS" : "RESET_ALL_WELLS",
    });
    setShowOperatorPopup(false);
    setShowRigPopup(false);
    setShowFormationPopup(false);
  };

  const { atomThemeVariant, isDark } = useCustomTheme();
  const visibleWells = useFilteredAllWells(wellSummaries.data?.wells || []);
  const shouldDisplayMapControls = useMemo(
    () => handleOnCircleAreaPress && handleOnPolygonAreaPress,
    [handleOnCircleAreaPress, handleOnPolygonAreaPress],
  );

  const [shouldShowCircleSweep, setShouldShowCircleSweep] = useState(false);
  const [shouldShowPolygonSweep, setShouldShowPolygonSweep] = useState(false);
  const [shouldShowBasinSweep, setShouldShowBasinSweep] = useState(false);

  const handleOnCircleHover = useCallback(() => {
    if (areaSelectionType === AreaSelectionType.Circle) {
      setShouldShowCircleSweep(true);
    }
  }, [areaSelectionType]);

  const handleOnCircleOut = useCallback(() => setShouldShowCircleSweep(false), []);
  const handleOnPolygonHover = useCallback(() => {
    if (areaSelectionType === AreaSelectionType.Polygon) {
      setShouldShowPolygonSweep(true);
    }
  }, [areaSelectionType]);

  const handleOnPolygonOut = useCallback(() => setShouldShowPolygonSweep(false), []);

  const handleOnBasinHover = useCallback(() => {
    if (basinName) {
      setShouldShowBasinSweep(true);
    }
  }, [basinName]);

  const handleOnBasinOut = useCallback(() => setShouldShowBasinSweep(false), []);
  const circleRadiusBoundsText = (
    <>
      {currentUom === IUnitSystem.METRIC ? "Km" : "Mi"} Radius{" "}
      {radius >= radiusMaxBound && <span style={{ color: colors.error_color }}>(Max)</span>}
    </>
  );

  const wellsText = useMemo(() => {
    if (wellSummaries.isLoading || !wellSummaries.data) {
      return "Loading Wells";
    }

    return `${isEvergreen ? "EverGreen" : ""} Wells ${(visibleWells ?? []).length.toLocaleString("en-US", {
      maximumFractionDigits: 0,
      minimumFractionDigits: 0,
    })}`;
  }, [wellSummaries, visibleWells, isEvergreen]);

  const handleOnRadiusChange = (value: ValueType | null) => {
    if (value && +value > 0 && +value <= radiusMaxBound) {
      const translatedValue =
        currentUom === IUnitSystem.METRIC
          ? +convertLength(+value, "kilometers", "meters").toFixed(0)
          : +convertLength(+value, "miles", "meters").toFixed(0);

      Track.interact(`${trackingTitle} - Circle radius change`, {
        "New Radius": `${translatedValue} m`,
      });

      setRadius(+translatedValue);
    }
  };
  useEffect(() => setShowBasinPopup(false), [basinName]);
  useEffect(() => {
    const handleScroll = () => {
      setShowBasinPopup(false);
      setShowRigPopup(false);
      setShowFormationPopup(false);
      setShowOperatorPopup(false);
      setWellSearchPopup(false);
    };
    window.addEventListener("scroll", handleScroll);
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  return (
    <HeaderContainer justify="space-between" align="middle" gutter={8} wrap={false} ref={containerRef}>
      <StyledCol>
        <StyledTitle level={2} variant={atomThemeVariant} weight={500}>
          {wellsText}
        </StyledTitle>
        <AutoComplete
          dropdownRender={(menu) => <AutoCompleteDropDown>{menu}</AutoCompleteDropDown>}
          open={wellSearchPopup}
          onDropdownVisibleChange={(e) => setWellSearchPopup(e)}
          allowClear
          options={quickSearchOptions}
          placeholder="Search by Well"
          style={{ width: "300px" }}
          onChange={(e) => setQuickSearchValue(e as string)}
          onKeyDown={(e) => {
            if (e.key === "Enter") {
              updateSearch(quickSearchValue);
            }
          }}
          onSelect={(e) => {
            if (lastSelectedFilter !== FilterType.Well && wellSummaries.data) {
              prevWellSummaries.current = wellSummaries.data;
            }
            setLastSelectedFilter(FilterType.Well);
            updateSearch(e as string);
          }}
          value={quickSearchValue}
          popupClassName="all-wells-autocomplete"
        />
        <StyledButton
          size="large"
          type={isDark ? "default" : "primary"}
          icon={<PDComponent.SvgIcon name="search" />}
          onClick={() => updateSearch(quickSearchValue)}
        />
      </StyledCol>
      <Col>
        <Row align="middle" gutter={8}>
          <Space>
            <RightContainer wrap>
              {/* Well Search Controls */}
              <PaddedSpace direction="horizontal" size={4}>
                <FilterTagsContainer>
                  <FilterTags tags={tags} />
                </FilterTagsContainer>
              </PaddedSpace>

              <PDComponent.VerticalDivider />
              <PaddedSpace direction="horizontal" size={4}>
                <Title level={3} variant="faded">
                  Filter by
                </Title>

                {/* Operator Selection Controls */}
                {!operators.isLoading && (
                  <Tooltip title="Operator">
                    <Popover
                      content={
                        <PDComponent.ComboBoxMultiSelect
                          placeholder="Search"
                          options={operatorOptions as IOption<number>[]}
                          values={operatorsState ?? (operatorOptions ?? []).filter((op) => op).map((op) => op.id)}
                          onReset={() => {
                            Track.interact(`${trackingTitle} - Operator Reset`);
                            resetFilters();
                          }}
                          onChange={(e) => {
                            if (lastSelectedFilter !== FilterType.Operator) {
                              prevOperatorIds.current = operatorIds.data;
                            }
                            Track.interact(`${trackingTitle} - Operator`, {
                              Action: "Update",
                            });
                            setLastSelectedFilter(FilterType.Operator);
                            updateOperators(e);
                            setShowOperatorPopup(false);
                          }}
                        />
                      }
                      trigger="click"
                      placement="bottom"
                      open={showOperatorPopup}
                      onOpenChange={(e) => setShowOperatorPopup(e)}
                      destroyTooltipOnHide
                    >
                      <Button
                        size="large"
                        icon={<PDComponent.SvgIcon name="identification" />}
                        type={
                          operatorsState !== null &&
                          (operatorsState ?? operatorOptions ?? []).length !== operators.data?.list.length
                            ? "primary"
                            : "default"
                        }
                        ghost={(operatorsState ?? operatorOptions ?? []).length !== operators.data?.list.length}
                        $engaged={showOperatorPopup}
                      />
                    </Popover>
                  </Tooltip>
                )}

                {/* Time Range controls */}
                <DatePicker
                  allowAllDates
                  onApply={updatePeriod}
                  selection={{
                    startDate: startDateState,
                    endDate: endDateState,
                  }}
                  title="Time Range"
                />
                <AllFilters
                  filters={wellFilters}
                  setFilters={setFilters}
                  scrollElement={document}
                  disabledFilters={["holeSection", "directionalInterval", "holeSize", "wellStatus", "phases"]}
                />

                {/* Rig Selection Controls */}
                {!rigs.isLoading && (
                  <Tooltip title="Rig">
                    <Popover
                      content={
                        <PDComponent.ComboBoxMultiSelect
                          placeholder="Search Rigs"
                          options={rigOptions}
                          values={rigIdsState ?? (rigOptions ?? []).map((rig) => rig.id)}
                          onReset={() => {
                            Track.interact(`${trackingTitle} - Rigs Reset`);
                            resetFilters();
                          }}
                          onChange={(e: number[]) => {
                            if (lastSelectedFilter !== FilterType.Rig) {
                              prevRigIds.current = rigIds.data;
                            }
                            Track.interact(`${trackingTitle} - Rigs`, {
                              Action: "Update",
                            });
                            setLastSelectedFilter(FilterType.Rig);
                            updateRigs(e);
                            setShowRigPopup(false);
                          }}
                        />
                      }
                      trigger="click"
                      placement="bottom"
                      open={showRigPopup}
                      onOpenChange={(e) => setShowRigPopup(e)}
                      destroyTooltipOnHide
                    >
                      <Button
                        size="large"
                        icon={<PDComponent.SvgIcon name="rig" />}
                        type={
                          _rigIdsState && rigIdsState.length !== initialRigIds.current?.length ? "primary" : "default"
                        }
                        ghost={!!(_rigIdsState && rigIdsState.length !== initialRigIds.current?.length)}
                        $engaged={showRigPopup}
                      />
                    </Popover>
                  </Tooltip>
                )}

                {/* Formation Filter */}
                {formations?.list ? (
                  <Tooltip title="Target Formation">
                    <Popover
                      content={
                        <PDComponent.ComboBoxMultiSelect
                          placeholder="Target Formation Name"
                          options={formations?.list.map((formation) => ({
                            id: formation.id,
                            name: formation.name || "Unknown formation",
                          }))}
                          values={formationIdsState ?? formations?.list.map((formation) => formation.id)}
                          onReset={() => {
                            Track.interact(`${trackingTitle} - Formations Reset`);
                            updateFormations(null);
                            setShowFormationPopup(false);
                          }}
                          onChange={(e) => {
                            if (lastSelectedFilter !== FilterType.Formation) {
                              prevFormationIds.current = formationIds;
                            }
                            Track.interact(`${trackingTitle} - Formations`, {
                              Action: "Update",
                            });
                            setLastSelectedFilter(FilterType.Formation);
                            updateFormations(e);
                            setShowFormationPopup(false);
                          }}
                        />
                      }
                      trigger={"click"}
                      placement="bottom"
                      open={showFormationPopup}
                      onOpenChange={(e) => setShowFormationPopup(e)}
                      destroyTooltipOnHide
                    >
                      <Button
                        size="large"
                        icon={<PDComponent.SvgIcon name="formation" />}
                        type={
                          formationIdsState && (formationIdsState ?? [])?.length !== formations.list.length
                            ? "primary"
                            : "default"
                        }
                        ghost={!!(formationIdsState && (formationIdsState ?? [])?.length !== formations.list.length)}
                        $engaged={showFormationPopup}
                      />
                    </Popover>
                  </Tooltip>
                ) : null}

                {/* Well State controls */}
                <Tooltip title="State">
                  <Popover
                    content={
                      <PDComponent.Pickoff
                        options={Object.values(WellStatusType).map((e) => ({ id: e, name: e }))}
                        values={statusState}
                        onChange={(e) => updateStatuses(e)}
                      />
                    }
                    trigger="click"
                    placement="bottom"
                    open={showStatusPopup}
                    onOpenChange={(e) => setShowStatusPopup(e)}
                    destroyTooltipOnHide
                  >
                    <Button
                      size="large"
                      icon={<PDComponent.SvgIcon name="checkmarkOutline" />}
                      type={statusState.length !== Object.keys(WellStatusType).length ? "primary" : "default"}
                      ghost={statusState.length !== Object.keys(WellStatusType).length}
                      $engaged={showStatusPopup}
                    />
                  </Popover>
                </Tooltip>
              </PaddedSpace>

              <PaddedSpace direction="horizontal" size={4}>
                {shouldDisplayMapControls ? (
                  <>
                    <Title level={3} variant="faded">
                      Map Area
                    </Title>

                    <ButtonGroup size="large">
                      <StyledCircleButton
                        size="large"
                        icon={
                          shouldShowCircleSweep ? (
                            <PDComponent.SvgIcon name="sweep" />
                          ) : (
                            <PDComponent.SvgIcon name="mapCenterIcon" height={18} />
                          )
                        }
                        type="default"
                        $engaged={areaSelectionType === AreaSelectionType.Circle}
                        onClick={() => {
                          if (shouldShowCircleSweep) {
                            Track.interact(`${trackingTitle} - Remove circle selection`);
                            if (handleOnTrashPress) {
                              handleOnTrashPress();
                            }
                          } else {
                            Track.interact(`${trackingTitle} - Add new circle`);
                            if (handleOnCircleAreaPress) {
                              handleOnCircleAreaPress();
                            }
                          }
                        }}
                        onMouseOver={handleOnCircleHover}
                        onMouseLeave={handleOnCircleOut}
                      />

                      {areaSelectionType === AreaSelectionType.Circle && (
                        <>
                          <StyledInputNumber
                            value={radius || 0}
                            type="number"
                            min={0}
                            step={1}
                            max={1000}
                            onChange={handleOnRadiusChange}
                          />

                          <StyledRadiusButton size="large" type="default" disabled>
                            {circleRadiusBoundsText}
                          </StyledRadiusButton>
                        </>
                      )}
                    </ButtonGroup>

                    <ButtonGroup>
                      <Button
                        size="large"
                        icon={
                          shouldShowPolygonSweep ? (
                            <PDComponent.SvgIcon name="sweep" />
                          ) : (
                            <PDComponent.SvgIcon name="polygon" height={18} />
                          )
                        }
                        type="default"
                        $engaged={areaSelectionType === AreaSelectionType.Polygon}
                        onClick={() => {
                          if (shouldShowPolygonSweep) {
                            Track.interact(`${trackingTitle} - Remove polygon selection`);
                            if (handleOnTrashPress) {
                              handleOnTrashPress();
                            }
                          } else {
                            if (handleOnPolygonAreaPress) {
                              handleOnPolygonAreaPress();
                            }
                          }
                        }}
                        onMouseOver={handleOnPolygonHover}
                        onMouseLeave={handleOnPolygonOut}
                      />
                      {areaSelectionType === AreaSelectionType.Polygon && (
                        <Button disabled size="large" type="default" ghost={false} $engaged={false}>
                          {areaText}
                        </Button>
                      )}
                    </ButtonGroup>
                    <Tooltip title="Basin Name">
                      <Popover
                        content={
                          <PDComponent.ListWithSearch<string>
                            placeholder={"Search by basin name"}
                            options={basinNames.sort().map((basin) => ({
                              id: basin,
                              name: basin,
                            }))}
                            values={basinNames}
                            selectedItem={basinName}
                            onClick={(item) => {
                              Track.interact(`${trackingTitle} - Select Basin`, { "Basin Name": item.name });
                              setBasinName(item.name);
                            }}
                          />
                        }
                        trigger="click"
                        placement="bottom"
                        open={showBasinPopup}
                        onOpenChange={(e) => setShowBasinPopup(e)}
                        destroyTooltipOnHide
                      >
                        <Row>
                          <Col>
                            <Button
                              size="large"
                              icon={
                                shouldShowBasinSweep ? (
                                  <PDComponent.SvgIcon name="sweep" />
                                ) : (
                                  <PDComponent.SvgIcon name="basins" width="1.1em" height="1.1em" />
                                )
                              }
                              type={"default"}
                              onMouseOver={handleOnBasinHover}
                              onMouseLeave={handleOnBasinOut}
                              onClick={
                                basinName
                                  ? () => {
                                      Track.interact(`${trackingTitle} - Remove basin selection`);
                                      setBasinName("");
                                      if (handleOnTrashPress) {
                                        handleOnTrashPress();
                                      }
                                    }
                                  : () => {}
                              }
                              style={
                                basinName
                                  ? {
                                      borderRight: "none",
                                      borderRadius: "5px 0px 0px 5px",
                                    }
                                  : undefined
                              }
                            />
                          </Col>
                          <Col>
                            {basinName ? (
                              <BasinName>
                                <Title level={3} variant={atomThemeVariant}>
                                  {basinName}
                                </Title>
                              </BasinName>
                            ) : null}
                          </Col>
                        </Row>
                      </Popover>
                    </Tooltip>
                  </>
                ) : null}

                <PDComponent.VerticalDivider />
                <Tooltip title={getTitleRealTimeIndicator(realTimeDataState)}>
                  <Button
                    size="large"
                    onClick={() => {
                      Track.interact(`${trackingTitle} - Real Time`, {
                        State: realTimeDataState === RealTimeDataEnum.ACTIVE ? "Disabled" : "Active",
                      });
                      setRealtimeData(
                        realTimeDataState === RealTimeDataEnum.ACTIVE
                          ? RealTimeDataEnum.DISABLED
                          : RealTimeDataEnum.ACTIVE,
                      );
                    }}
                    icon={<RealTimeIndicator realTimeDataState={realTimeDataState} />}
                  />
                </Tooltip>
                <PDComponent.VerticalDivider />
                {userData?.role && highPrivilegeRoles.includes(userData?.role) && !isEvergreen && !isInDemoMode ? (
                  <Tooltip title="New Well">
                    <Link to="/well/new">
                      <Button
                        size="large"
                        type={isDark ? "default" : "primary"}
                        onClick={() => Track.interact("Navigate - New Well")}
                      >
                        New Well
                      </Button>
                    </Link>
                  </Tooltip>
                ) : null}
              </PaddedSpace>
            </RightContainer>
          </Space>
        </Row>
      </Col>
    </HeaderContainer>
  );
};

export default AllWellsHeader;
