import type { UseQueryOptions } from "@tanstack/react-query";
import { useSuspenseQuery } from "@tanstack/react-query";
import type { ApiFiltersFormationsByWellsPutRequest} from "apis/oag";
import { FiltersApi } from "apis/oag";
import { apiConfig } from "utils/apiConfig";
import { RequestUID } from "utils/queryNamespaces";

import { useRigsPeriodState } from "./useRigsPeriodState";

const filters = new FiltersApi(apiConfig);

export function useActiveFormations({
  wellIds,
  keepEvengreenOnly = false,
  options,
}: {
  wellIds?: number[] | null;
  keepEvengreenOnly?: boolean;
  options?: UseQueryOptions<number[]>;
}) {
  const periodState = useRigsPeriodState();

  const requestParameters: ApiFiltersFormationsByWellsPutRequest = {
    availableIdsByWellsQueryDto: {
      from: periodState.from,
      to: periodState.to,
      keepEvengreenOnly: keepEvengreenOnly,
      wellIds: wellIds,
    },
  };

  const ActiveFormationsQueryKey = { uid: RequestUID.activeFormations, requestParameters };

  return useSuspenseQuery<number[]>({
    queryKey: [ActiveFormationsQueryKey],
    queryFn: () => filters.apiFiltersFormationsByWellsPut(requestParameters),
    ...options,
  });
}
