import type { RigCardFactSeriesDto, RigFleetPerformanceCardUserLensDto } from "apis/oag";
import { DashboardType, PivotOrderType, PivotType, ResultDataState } from "apis/oag";
import { CustomSwitch } from "atoms/common";
import { Title } from "atoms/Typography";
import { Loader } from "components/Loader";
import { PDComponent } from "components/PDComponents";
import { getPivotOrderIcon, getPivotOrderTypeTitle, Item, MenuPD } from "components/RigComparisonSelectors/DisplayOptionsModal";
import { useRigFleetPerformanceCardFacts } from "hooks/useRigFleetPerformanceCardFacts";
import { useUserLenses } from "hooks/useUserLenses";
import { useUserLensTabs } from "hooks/useUserLensTabs";
import { useSelectedRigsContext } from "pages/AllRigs/RigList/SelectedRigsContext";
import { RigCardLayoutType, useAllRigOptions } from "pages/AllRigs/utils";
import { useEffect, useMemo, useState } from "react";
import { useAppDispatch } from "reducers/store";
import { Track } from "services/Mixpanel";
import { Button, Col, Divider, Popover, Radio, Row, Space } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";
import { zIndexLayer } from "utils/zIndex";

import LeaderboardCard from "./Card";
import NoDataCard from "./Card/NoDataCard";
import { isDrillingProductivityLens } from "./Card/utils";
import { SelectedMetric, useLeaderboardMetricSelectionContext } from "./LeaderboardMetricSelectionContext";
import { ScoreSelector } from "./ScoreSelector/ScoreSelector";
import {
  RadioButtonGroup,
  StyledDropdownContainer,
  StyledLeaderboardGrid,
  StyledLeaderboardSection,
  StyledLensTabButton,
  StyledLOptionsRow,
  StyledLTabsRow,
} from "./styled";
import { isTimePivot } from "./utils";

const groupByCriteria = [
  { label: "Rig", value: PivotType.Rig },
  { label: "Well", value: PivotType.Well },
  { label: "Month", value: PivotType.Month },
  { label: "Quarter", value: PivotType.Quarter },
  { label: "Operator", value: PivotType.Operator },
];

export default function LeaderboardSection() {
  const { data: allLensTabs, isFetching: isLoadingLensTabs } = useUserLensTabs(DashboardType.RigFleetPerformance, {
    staleTime: Infinity,
    gcTime: Infinity,
  });
  const { data: userLensesRemote, isFetching: isLoadingLenses } = useUserLenses({
    staleTime: Infinity,
    gcTime: Infinity,
  });

  const { isDataAnonymity, setIsDataAnonymity } = useSelectedRigsContext();

  const [userLenses, setUserLenses] = useState(userLensesRemote);
  const { isDark, themeStyle } = useCustomTheme();

  const [selectedTab, setSelectedTab] = useState<number | null>(null);
  const selectedLenses = userLenses?.byTab[selectedTab ?? 0];
  const [leaderboardCards, setLeaderboardCards] = useState<RigCardFactSeriesDto[]>([]);

  const { selectedMetric } = useLeaderboardMetricSelectionContext();
  const isNetTimesOrScores = useMemo(() => selectedMetric !== SelectedMetric.KPI, [selectedMetric]);

  const overallTab = allLensTabs?.find((tab) => tab?.name === "Overall");
  const isOverallTabSelected = overallTab?.id === selectedTab;

  // When overall tab is selected, we need to consider all the lenses from all the tabs
  const selectedTabIdForFact = isOverallTabSelected ? undefined : [selectedTab ?? -1];

  const { data: scoreData, isFetching: isLoadingFacts } = useRigFleetPerformanceCardFacts(selectedTabIdForFact, {
    staleTime: Infinity,
    gcTime: Infinity,
    enabled: !!selectedTab,
  });
  const { rigOptions, setLayoutType, setPivot } = useAllRigOptions();

  const sortingOptions = useMemo(() => {
    if (!rigOptions.pivot) return [];
    if (rigOptions.pivot === PivotType.Well) {
      return [
        PivotOrderType.PivotValueAscending, // rank
        PivotOrderType.PivotValueDescending,
        PivotOrderType.PivotKeyAscending, // name
        PivotOrderType.PivotKeyDescending,
        PivotOrderType.WellSpudDateAscending,
        PivotOrderType.WellSpudDateDescending,
        PivotOrderType.WellLastActivityDateAscending,
        PivotOrderType.WellLastActivityDateDescending,
      ];
    }
    if (
      rigOptions.pivot === PivotType.Rig ||
      rigOptions.pivot === PivotType.Operator
    )
      return [
        PivotOrderType.PivotValueAscending, // rank
        PivotOrderType.PivotValueDescending,
        PivotOrderType.PivotKeyAscending, // name
        PivotOrderType.PivotKeyDescending,
      ];
    if (
      rigOptions.pivot === PivotType.Month ||
      rigOptions.pivot === PivotType.Quarter
    )
      return [
        PivotOrderType.LastUpdateAscending, // name
        PivotOrderType.LastUpdateDescending,
        PivotOrderType.PivotValueAscending, // rank
        PivotOrderType.PivotValueDescending,
      ];
    return [];
  }, [rigOptions?.pivot]);
  const [selectedSortingOption, setSelectedSortingOption] = useState<PivotOrderType>(sortingOptions[0]);

  const rigScores = useMemo(() => scoreData, [scoreData]);

  const [sortingGroupOpen, setSortingGroupOpen] = useState(false);
  useEffect(() => {
    setSelectedSortingOption(
      sortingOptions[0]
    );
  }, [rigOptions.pivot, sortingOptions]);
  const visibleLensTabs = useMemo(() => {
    // If the selected metric is KPI, we do not want to show "Overall" tab
    return allLensTabs?.filter((tab) => (tab.name === "Overall" ? isNetTimesOrScores : true));
  }, [allLensTabs, isNetTimesOrScores]);

  const isLoading = useMemo(
    () => isLoadingLensTabs || isLoadingFacts || isLoadingLenses,
    [isLoadingFacts, isLoadingLensTabs, isLoadingLenses],
  );


  useEffect(() => {
    if (visibleLensTabs?.length && !selectedTab) {
      setSelectedTab(visibleLensTabs[0].id);
    }
  }, [selectedTab, visibleLensTabs]);
  const dispatch = useAppDispatch();
  useEffect(() => {
    dispatch({
      type: "ALL_RIGS_FULL_WELLS_INCLUDED",
      payload: !isTimePivot(rigOptions.pivot),
    });
  }, [dispatch, rigOptions.pivot]);
  useEffect(() => {
    if (!isLoading) {
      // To sync different loading stats and make the lens wait until everything is loaded
      const cards = (rigScores?.cardGroups || [])
        .flatMap((group) => group.facts)
        .filter((card) => (selectedLenses ?? []).map((lens) => lens.id).includes(card.userLensId));

      setLeaderboardCards(cards);
      setUserLenses(userLensesRemote);
    }
  }, [rigScores?.cardGroups, isLoading, selectedLenses, userLensesRemote]);

  useEffect(() => {
    if (!isNetTimesOrScores && isOverallTabSelected) {
      setSelectedTab(visibleLensTabs?.[0].id || 0);
    }
  }, [isNetTimesOrScores, isOverallTabSelected, visibleLensTabs]);

  return isLoading ? (
    <Loader withWrapper zIndex={zIndexLayer.mars} centered position="absolute" />
  ) : (
    <StyledLeaderboardSection>
      <Row justify="space-between">
        <Col>
          <StyledLTabsRow>
            {visibleLensTabs?.map((tab) => (
              <Col key={tab.id} flex="0 auto">
                <StyledLensTabButton
                  $active={tab.id === selectedTab}
                  onClick={() => {
                    Track.interact("All rigs - Change Lens Tab ", {
                      "Tab Name": tab.name,
                    });
                    setSelectedTab(tab.id);
                  }}
                >
                  {tab.name}
                </StyledLensTabButton>
              </Col>
            ))}
          </StyledLTabsRow>
        </Col>
        <Col flex="0 auto" style={{ paddingRight: 15 }}>
          <Row gutter={6} align="middle" style={{ paddingTop: 12 }}>
            <Col>
              <ScoreSelector />
            </Col>
          </Row>
        </Col>
      </Row>
      <StyledLOptionsRow>
        <Row gutter={3}>
          <Col>
            <StyledDropdownContainer>
              <PDComponent.Dropdown<PivotType>
                variant="button"
                selectedOption={rigOptions.pivot}
                handleOptionUpdate={setPivot}
                options={groupByCriteria}
                trackingText="Leaderboard Cards - Update Pivot"
              />
            </StyledDropdownContainer>
          </Col>
          <Col>
            {sortingOptions?.length > 0 ? (
              <Col style={{
                height: "100%",
              }}>
                <Popover
                  content={
                    <MenuPD>
                      {sortingOptions.map((sortOption) => (
                        <Item
                          key={sortOption}
                          isActive={false}
                          isSelected={
                            selectedSortingOption === sortOption}
                          onClick={() => {
                            setSelectedSortingOption(sortOption);
                            setSortingGroupOpen(false);
                          }}
                        >
                          <div>
                            {getPivotOrderIcon({ pivot: rigOptions.pivot, pivotSort: sortOption })}{" "}
                            {getPivotOrderTypeTitle({ pivot: rigOptions.pivot, pivotSort: sortOption })}
                          </div>
                        </Item>
                      ))}
                    </MenuPD>
                  }
                  open={sortingGroupOpen}
                  onOpenChange={(value) =>
                    setSortingGroupOpen(value)
                  }
                  placement="bottomLeft"
                  trigger={["click"]}
                >
                  <Button style={{
                    height: "100%",
                    background: themeStyle.colors.phase_indicator,
                    color: themeStyle.colors.black_white,
                    border: "none",

                  }}>
                    {getPivotOrderIcon({
                      pivot: rigOptions.pivot,
                      pivotSort: selectedSortingOption,
                    })}
                  </Button>
                </Popover>
              </Col>
            ) : null}
          </Col>
        </Row>

        <Space>
          <Title variant={isDark ? "white" : "faded"} level={5} weight={500}>
            Cards Layout
          </Title>
          <RadioButtonGroup
            buttonStyle="solid"
            value={rigOptions.layoutType}
            onChange={(e) => {
              setLayoutType(e.target.value as RigCardLayoutType);
            }}
          >
            <Radio.Button
              value={RigCardLayoutType.BarsOnly}
              onClick={() => {
                Track.interact("Leaderboard Cards - Update View", {
                  "Updated View": RigCardLayoutType.BarsOnly,
                });
              }}
            >
              <PDComponent.SvgIcon name="leaderboardChartView" width={22} />
            </Radio.Button>
            <Radio.Button
              value={RigCardLayoutType.TableOny}
              onClick={() => {
                Track.interact("Leaderboard Cards - Update View", {
                  "Updated View": RigCardLayoutType.TableOny,
                });
              }}
            >
              <PDComponent.SvgIcon name="leaderboardTableView" width={22} />
            </Radio.Button>
            <Radio.Button
              value={RigCardLayoutType.Split}
              onClick={() => {
                Track.interact("Leaderboard Cards - Update View", {
                  "Updated View": RigCardLayoutType.Split,
                });
              }}
            >
              <PDComponent.SvgIcon name="leaderboardSplitView" width={22} svgStyle={{ padding: 3 }} />
            </Radio.Button>
          </RadioButtonGroup>

          <Divider type="vertical" />
          <Title variant={isDark ? "white" : "faded"} level={5} weight={500}>
            Anonymize
          </Title>
          <CustomSwitch
            $isChecked={!!isDataAnonymity}
            checked={isDataAnonymity}
            onChange={(checked) => {
              Track.interact("Rig Leaderboard - Anonymize Button", {
                "Anonymize Data": checked,
              });
              setIsDataAnonymity(checked);
            }}
          />
        </Space>
      </StyledLOptionsRow>

      <StyledLeaderboardGrid $hasOneLensDisplayed={isOverallTabSelected}>
        {leaderboardCards.map((card) => {
          const lens = userLenses?.byId[card?.userLensId] as RigFleetPerformanceCardUserLensDto;
          const shouldDisplayNoDataCard =
            (isDrillingProductivityLens(lens) && isNetTimesOrScores) || card?.dataState === ResultDataState.NoData;

          return shouldDisplayNoDataCard ? (
            <NoDataCard key={card?.userLensId} lens={lens} isSingleOnGrid={isOverallTabSelected} />
          ) : (
            <LeaderboardCard
              key={card?.userLensId}
              cardId={card?.userLensId}
              data={card}
              lens={lens}
              order={selectedSortingOption}
              rigPivot={rigOptions.pivot}
              isSingleOnGrid={isOverallTabSelected}
            />
          );
        })}
      </StyledLeaderboardGrid>
    </StyledLeaderboardSection>
  );
}
