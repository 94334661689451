import type { RangeType } from "atoms/DatePicker";
import { useMemo } from "react";
import { useParams } from "react-router-dom";
import { useAppSelector } from "reducers/store";
import { defaultDateDto } from "utils/common";
import { dateToDateOnlyDto, last30Days, today } from "utils/helper";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";
import { useDashboardType } from "./useDashboardType";

export const useRigsPeriodState = () => {
  const { isRig, isRootDashboardPage } = useDashboardType();
  const allRigsPeriodState = useAppSelector((state) => state.allRigs.period);
  const { lensId: id } = useParams<{ lensId: string }>();
  const isDetailedView = !!id;
  const [rigDashboardPeriodState] = useStateQuery<RangeType>(
    isDetailedView ? URL_STATE_PARAM.PERIOD_RIG_WIDGET : URL_STATE_PARAM.PERIOD_RIG_DASHBOARD,
    {
      startDate: last30Days,
      endDate: today,
    },
  );
  const periodState = useMemo(() => {
    if (isRig) {
      if (isRootDashboardPage && rigDashboardPeriodState.startDate && rigDashboardPeriodState.endDate) {
        return {
          from: dateToDateOnlyDto(rigDashboardPeriodState.startDate),
          to: dateToDateOnlyDto(rigDashboardPeriodState.endDate),
        };
      }
      if (allRigsPeriodState.startDate && allRigsPeriodState.endDate) {
        return {
          from: dateToDateOnlyDto(allRigsPeriodState.startDate),
          to: dateToDateOnlyDto(allRigsPeriodState.endDate),
        };
      }
    }
    return defaultDateDto;
  }, [isRig, isRootDashboardPage, rigDashboardPeriodState, allRigsPeriodState]);

  return periodState;
};
