import { Group } from "@visx/group";
import { Bar } from "@visx/shape";
import type { TvdPhaseDto } from "apis/oag";
import { WellStatusType } from "apis/oag";
import { RIGHT_AXIS_PADDING } from "components/TvDChart/constants";
import type { ScaleLinear } from "d3-scale";
import { usePhases } from "hooks/usePhases";
import { useMemo } from "react";
import { useAppSelector } from "reducers/store";
import colors from "utils/colors";
import { secondsInDay } from "utils/common";
import { Tooltip } from "utils/componentLibrary";
import { useCustomTheme } from "utils/useTheme";

const BAR_HEIGHT = 38;
const APPROX_LETTER_WIDTH_PX = 6.7;

export const WellPhases = ({
  chartWidth,
  isDirectionalIntervalsVisible,
  visiblePhases,
  paddingLeft,
  xScale,
  isLoading: isLoadingTvd,
  isReport,
}: {
  chartWidth: number;
  visiblePhases: TvdPhaseDto[];
  isDirectionalIntervalsVisible: boolean | null | undefined;
  paddingLeft: number;
  xScale: ScaleLinear<number, number, never>;
  isLoading: boolean;
  isReport?: boolean;
}) => {
  const { data: phaseDefinitions, isLoading } = usePhases();
  const selectedWellDetails = useAppSelector((state) => state.timeline.currentWellDetails);
  const isActiveWell = selectedWellDetails?.status === WellStatusType.Active;

  const {
    isDark,
    themeStyle: { colors: themeColors },
  } = useCustomTheme();

  const colorWellPhases: Record<string, string> = {
    "Pre-Drill": isDark ? colors.bauhaus : colors.lady_nicole,
    "Surface Drill": isDark ? colors.ravenwood : colors.laid_back_grey,
    "Surface Flat": isDark ? colors.volcanic_rock : colors.rampart,
    "Intermediate Flat": isDark ? colors.hulett_ore : colors.gazebo_grey,
    "Intermediate Drill": isDark ? colors.private_black : colors.rampart,
    "Production Flat": isDark ? colors.dry_mud : colors.evening_white,
    "Production Drill": isDark ? colors.charadon_granite : colors.overdue_grey,
    Unknown: isDark ? colors.tuatara : colors.crystal_brooke,
  };

  const textY = useMemo(() => {
    if (isReport) {
      return "98.5%";
    } else return isDirectionalIntervalsVisible ? "89.5%" : "97.5%";
  }, [isDirectionalIntervalsVisible, isReport]);

  return (
    <Group>
      {!isLoading &&
        !isLoadingTvd &&
        visiblePhases.map((phase, i) => {
          const name = phaseDefinitions?.find((phaseDefinition) => phaseDefinition.id === phase.phasesId)?.name ?? "";
          const color = colorWellPhases[name];
          const isLast = i === visiblePhases.length - 1;

          const addedPadding = i === 0 ? paddingLeft : 0;
          const segmentLength = xScale((phase.endDynamicDuration - phase.startDynamicDuration) / secondsInDay);
          let activePadding = 0;

          if (isLast && !isActiveWell) {
            activePadding =
              (!isReport ? RIGHT_AXIS_PADDING * 2 : 0) + chartWidth - xScale(phase.endDynamicDuration / secondsInDay);
          }

          const width = Math.max(addedPadding + segmentLength + activePadding, 0);

          const x = (i === 0 ? 0 : paddingLeft) + (xScale((phase.startDynamicDuration ?? 0) / secondsInDay) ?? 0);

          const shouldShowText = width > APPROX_LETTER_WIDTH_PX * name.length;

          const textX = x + width / 2;

          return (
            <Tooltip
              key={`phases-${phase.startDynamicDuration}-${phase.endDynamicDuration}-${phase.phasesId}-${i}-tooltip`}
              placement="top"
              overlayStyle={{ whiteSpace: "pre-line" }}
              title={name}
              arrowPointAtCenter
              destroyTooltipOnHide
            >
              <Group
                key={`phases-${phase.startDynamicDuration}-${phase.endDynamicDuration}-${phase.phasesId}-${i}-group`}
              >
                <Bar
                  width={Number.isNaN(width) ? 0 : width}
                  x={Number.isNaN(x) ? 0 : x}
                  height={BAR_HEIGHT}
                  fill={color}
                  y={`calc(100% - ${BAR_HEIGHT * (isDirectionalIntervalsVisible ? 2 : 1)}px)`}
                />

                {isActiveWell && isLast ? (
                  <Bar
                    width={3}
                    x={(Number.isNaN(x) ? 0 : x) + (Number.isNaN(width) ? 0 : width)}
                    height={BAR_HEIGHT}
                    fill={colors.well_color}
                    y={`calc(100% - ${BAR_HEIGHT * (isDirectionalIntervalsVisible ? 2 : 1)}px)`}
                  />
                ) : null}

                {shouldShowText ? (
                  <text
                    fill={themeColors.primary_typography}
                    fontSize={14}
                    letterSpacing={-0.2}
                    pointerEvents="none"
                    textAnchor="middle"
                    x={Number.isNaN(textX) ? 0 : textX}
                    style={{ userSelect: "none" }}
                    y={textY}
                  >
                    {name}
                  </text>
                ) : null}
              </Group>
            </Tooltip>
          );
        })}
    </Group>
  );
};
