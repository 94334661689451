import { WellStatusType } from "apis/oag";
import { useDashboardType } from "hooks/useDashboardType";
import { useFormations } from "hooks/useFormations";
import { useOperators } from "hooks/useOperators";
import { useOperatorsActiveRigs } from "hooks/useOperatorsActiveRigs";
import { useRigActiveToWells } from "hooks/useRigActiveToWells";
import { useRigs } from "hooks/useRigs";
import { useRigsActiveOperators } from "hooks/useRigsActiveOperators";
import { useWellShortInfo } from "hooks/useWellShortInfo";
import { getCombinedFilters } from "pages/AllRigs/Header/LinkedSelectors";
import { useMemo } from "react";
import type { IFilterOptions } from "reducers/allRigsReducer";
import { useAppSelector } from "reducers/store";

import type { FilterTag } from "./FilterTags";
import type { FilterTypes } from "./useFiltersCategories";
import { useFiltersCategories } from "./useFiltersCategories";

export const useFilterTags = ({
  updateOperators,
  updateRigs,
  updateFormations,
  updateStatuses,
  setFilters,
  wellStatusState,
  operatorsState,
  rigIdsState,
  formationIdsState,
  filters,
  onWellsChange,
  selectedWells,
}: {
  updateOperators: (operatorIds: number[] | null) => void;
  updateRigs: (rigIds: number[] | null) => void;
  updateFormations: (formationIds: number[] | null) => void;
  updateStatuses?: (f: WellStatusType[]) => boolean;
  setFilters: (localFilters: IFilterOptions | null) => void;
  wellStatusState?: WellStatusType[];
  operatorsState: number[];
  rigIdsState: number[];
  formationIdsState: number[] | null;
  filters: IFilterOptions | null;
  onWellsChange?: (selectedWells: number[] | null) => void;
  selectedWells?: number[];
}) => {
  const { localFilters, setLocalFilters, allFilters } = useFiltersCategories(filters, setFilters);
  const operators = useOperators();
  const rigs = useRigs();
  const { data: wellShortInfo } = useWellShortInfo();

  const { isEvergreen } = useDashboardType();
  const selectedRigs = useAppSelector((state) => state.allRigs.rigIds);

  const rigIds = useOperatorsActiveRigs(operatorsState, isEvergreen);
  const operatorIds = useRigsActiveOperators(rigIdsState || [], isEvergreen);
  const selectedRigsValues = selectedRigs ?? rigs.data?.list.map((rig) => rig.id) ?? [];
  const { data: wellDataFilteredBySelectedRigs, isLoading: isLoadingWells } = useRigActiveToWells(selectedRigsValues);

  const combinedWellsOptions = getCombinedFilters({
    selectedItems: selectedWells || [],
    options: wellDataFilteredBySelectedRigs,
  });

  const { data: formations } = useFormations();

  const operatorOptions = useMemo(() => {
    const list = operatorIds.data;
    const set = new Set(list ?? []);
    if (operators.data?.byId) return Array.from(set).map((operatorId) => operators.data?.byId[operatorId]);
    else return [];
  }, [operatorIds.data, operators.data?.byId]);

  const rigOptions = useMemo(() => {
    const list = rigIds.data;
    const set = new Set(list ?? []);
    if (rigs.data?.byId) return Array.from(set);
    else return [];
  }, [rigIds.data, rigs.data?.byId]);

  const tags = useMemo(() => {
    let tags: FilterTag[] = [];
    if (!operators.data || !rigs.data) return [];

    // OPERATOR STATE FILTERS
    const operatorSelectedOptionsCount = operatorOptions.reduce((acc, operatorOption) => {
      if (operatorsState?.includes(operatorOption?.id)) return acc + 1;
      else return acc;
    }, 0);

    if (operatorIds.data && operatorSelectedOptionsCount < operatorOptions.length && operatorsState?.length >= 1) {
      const plusText = ` +${operatorSelectedOptionsCount}`;
      tags = tags.concat({
        onRemove: () => updateOperators(null),
        text: operatorsState?.length > 1 ? `Operators ${plusText}` : operators.data.byId[operatorsState[0]].name || "",
      });
    }

    // RIG FILTERS
    const rigSelectedOptionsCount = rigOptions.reduce((acc, rigOption) => {
      if (rigIdsState?.includes(rigOption)) return acc + 1;
      else return acc;
    }, 0);

    if (rigIds?.data && rigSelectedOptionsCount < rigOptions.length && rigIdsState?.length >= 1) {
      const plusText = ` +${rigSelectedOptionsCount}`;
      tags = tags.concat({
        onRemove: () => updateRigs(null),
        text: rigIdsState?.length > 1 ? `Rigs ${plusText}` : rigs.data.byId[rigIdsState[0]].shortName || "",
      });
    }

    // WELL FILTERS
    if (
      selectedWells &&
      !isLoadingWells &&
      selectedWells?.length < (wellShortInfo?.list?.length ?? 0) &&
      selectedWells?.length >= 1
    ) {
      const plusText = ` +${combinedWellsOptions.length}`;
      tags = tags.concat({
        onRemove: () => onWellsChange?.(null),
        text: selectedWells?.length > 1 ? `Wells ${plusText}` : wellShortInfo?.byId[selectedWells[0]]?.name || "",
      });
    }

    if (formationIdsState && formationIdsState.length) {
      tags = tags.concat({
        onRemove: () => updateFormations(null),
        text:
          formationIdsState.length > 1
            ? `Formations +${formationIdsState.length}`
            : formations?.list.find((formation) => formation.id === formationIdsState[0])?.name || "",
      });
    }

    if (wellStatusState && wellStatusState.length && wellStatusState.length < Object.values(WellStatusType).length) {
      tags = tags.concat({
        onRemove: () => updateStatuses?.(Object.values(WellStatusType)),
        text:
          wellStatusState.length > 1
            ? `${wellStatusState[0]} +${wellStatusState.length - 1}`
            : wellStatusState[0] || "",
      });
    }

    // MODAL FILTERS
    (Object.keys(allFilters) as Array<FilterTypes>).forEach((filterType) => {
      const selectedFilterOptions = localFilters?.[filterType];
      const allFilterItems = allFilters?.[filterType]?.options;
      if (
        selectedFilterOptions &&
        selectedFilterOptions.length &&
        allFilterItems &&
        selectedFilterOptions?.length !== allFilterItems?.length
      ) {
        const label = selectedFilterOptions[0].value;

        const finalFilterText =
          (selectedFilterOptions?.length > 1 ? label + ` +${selectedFilterOptions?.length - 1}` : label) + "";

        tags = tags.concat({
          onRemove: () =>
            setLocalFilters((prev) => {
              if (prev) {
                const updatedFilters = { ...prev, [filterType]: allFilterItems };
                setFilters(updatedFilters);
                return updatedFilters;
              }
              return prev;
            }),
          text: finalFilterText,
        });
      }
    });

    return tags;
  }, [
    isLoadingWells,
    wellShortInfo?.byId,
    wellShortInfo?.list?.length,
    combinedWellsOptions.length,
    onWellsChange,
    selectedWells,
    wellStatusState,
    allFilters,
    setFilters,
    formationIdsState,
    formations?.list,
    localFilters,
    operatorIds.data,
    operatorOptions,
    operators.data,
    operatorsState,
    rigIds?.data,
    rigIdsState,
    rigOptions,
    rigs.data,
    setLocalFilters,
    updateFormations,
    updateOperators,
    updateStatuses,
    updateRigs,
  ]);

  return { tags };
};
