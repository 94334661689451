/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DimensionType } from './DimensionType';
import {
    DimensionTypeFromJSON,
    DimensionTypeFromJSONTyped,
    DimensionTypeToJSON,
} from './DimensionType';
import type { TrackFactType } from './TrackFactType';
import {
    TrackFactTypeFromJSON,
    TrackFactTypeFromJSONTyped,
    TrackFactTypeToJSON,
} from './TrackFactType';

/**
 * 
 * @export
 * @interface TrackDto
 */
export interface TrackDto {
    /**
     * 
     * @type {number}
     * @memberof TrackDto
     */
    id: number;
    /**
     * 
     * @type {string}
     * @memberof TrackDto
     */
    name?: string | null;
    /**
     * 
     * @type {string}
     * @memberof TrackDto
     */
    description?: string | null;
    /**
     * 
     * @type {DimensionType}
     * @memberof TrackDto
     */
    dimension: DimensionType;
    /**
     * 
     * @type {TrackFactType}
     * @memberof TrackDto
     */
    factType: TrackFactType;
}



/**
 * Check if a given object implements the TrackDto interface.
 */
export function instanceOfTrackDto(value: object): value is TrackDto {
    if (!('id' in value) || value['id'] === undefined) return false;
    if (!('dimension' in value) || value['dimension'] === undefined) return false;
    if (!('factType' in value) || value['factType'] === undefined) return false;
    return true;
}

export function TrackDtoFromJSON(json: any): TrackDto {
    return TrackDtoFromJSONTyped(json, false);
}

export function TrackDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): TrackDto {
    if (json == null) {
        return json;
    }
    return {
        
        'id': json['Id'],
        'name': json['Name'] == null ? undefined : json['Name'],
        'description': json['Description'] == null ? undefined : json['Description'],
        'dimension': DimensionTypeFromJSON(json['Dimension']),
        'factType': TrackFactTypeFromJSON(json['FactType']),
    };
}

export function TrackDtoToJSON(value?: TrackDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'Id': value['id'],
        'Name': value['name'],
        'Description': value['description'],
        'Dimension': DimensionTypeToJSON(value['dimension']),
        'FactType': TrackFactTypeToJSON(value['factType']),
    };
}

