/* tslint:disable */
/* eslint-disable */
/**
 * Aaip API
 * No description provided (generated by Openapi Generator https://github.com/openapitools/openapi-generator)
 *
 * The version of the OpenAPI document: 1.0
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { mapValues } from '../runtime';
import type { DateDto } from './DateDto';
import {
    DateDtoFromJSON,
    DateDtoFromJSONTyped,
    DateDtoToJSON,
} from './DateDto';

/**
 * 
 * @export
 * @interface AvailableIdsByFormationsQueryDto
 */
export interface AvailableIdsByFormationsQueryDto {
    /**
     * 
     * @type {boolean}
     * @memberof AvailableIdsByFormationsQueryDto
     */
    keepEvengreenOnly: boolean;
    /**
     * 
     * @type {DateDto}
     * @memberof AvailableIdsByFormationsQueryDto
     */
    from: DateDto;
    /**
     * 
     * @type {DateDto}
     * @memberof AvailableIdsByFormationsQueryDto
     */
    to: DateDto;
    /**
     * 
     * @type {Array<number>}
     * @memberof AvailableIdsByFormationsQueryDto
     */
    formationIds?: Array<number> | null;
}

/**
 * Check if a given object implements the AvailableIdsByFormationsQueryDto interface.
 */
export function instanceOfAvailableIdsByFormationsQueryDto(value: object): value is AvailableIdsByFormationsQueryDto {
    if (!('keepEvengreenOnly' in value) || value['keepEvengreenOnly'] === undefined) return false;
    if (!('from' in value) || value['from'] === undefined) return false;
    if (!('to' in value) || value['to'] === undefined) return false;
    return true;
}

export function AvailableIdsByFormationsQueryDtoFromJSON(json: any): AvailableIdsByFormationsQueryDto {
    return AvailableIdsByFormationsQueryDtoFromJSONTyped(json, false);
}

export function AvailableIdsByFormationsQueryDtoFromJSONTyped(json: any, ignoreDiscriminator: boolean): AvailableIdsByFormationsQueryDto {
    if (json == null) {
        return json;
    }
    return {
        
        'keepEvengreenOnly': json['KeepEvengreenOnly'],
        'from': DateDtoFromJSON(json['From']),
        'to': DateDtoFromJSON(json['To']),
        'formationIds': json['FormationIds'] == null ? undefined : json['FormationIds'],
    };
}

export function AvailableIdsByFormationsQueryDtoToJSON(value?: AvailableIdsByFormationsQueryDto | null): any {
    if (value == null) {
        return value;
    }
    return {
        
        'KeepEvengreenOnly': value['keepEvengreenOnly'],
        'From': DateDtoToJSON(value['from']),
        'To': DateDtoToJSON(value['to']),
        'FormationIds': value['formationIds'],
    };
}

