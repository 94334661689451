import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import type { AllWellsDataFeedQueryDto } from "apis/oag";
import { DataFeedsApi } from "apis/oag";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { URL_STATE_PARAM, useStateQuery } from "hooks/navigation/useQueryState";
import { useDashboardType } from "hooks/useDashboardType";
import { useOperatorsActiveRigs } from "hooks/useOperatorsActiveRigs";
import { useRigsActiveOperators } from "hooks/useRigsActiveOperators";
import { useCallback, useEffect, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { useDebounce } from "use-debounce";
import { API_REFRESH_INTERVAL, apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateOnlyDto } from "utils/helper";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

const DataFeeds = new DataFeedsApi(apiConfig);

export const useAllWellsDataFeed = (isEnabled: boolean, options?: UseQueryOptions<boolean>) => {
  const { isEvergreen } = useDashboardType();
  const searchState = useAppSelector((state) => state.allWells.search);
  const operatorsState = useAppSelector((state) => state.allWells.operators);
  const rigIdsState = useAppSelector((state) => state.allWells.rigIds);
  const statusState = useAppSelector((state) => state.allWells.status);
  const startDateState = useAppSelector((state) => state.allWells.startDate);
  const endDateState = useAppSelector((state) => state.allWells.endDate);
  const formationState = useAppSelector((state) => state.allWells.formationIds);
  const queryClient = useQueryClient();
  const rigIds = useOperatorsActiveRigs(operatorsState || [], isEvergreen);
  const operatorIds = useRigsActiveOperators(rigIdsState || [], isEvergreen);

  const lastAllWellRefreshDate = useAppSelector((state) => state.allWells.lastAllWellRefreshDate);
  const [realTimeDataState] = useStateQuery<RealTimeDataEnum>(
    URL_STATE_PARAM.REALTIME_DATA_DASHBOARD,
    RealTimeDataEnum.UNAVAILABLE,
  );

  const refreshAllWells = useCallback(
    (shallRefresh: string) => {
      if (shallRefresh === "true") {
        queryClient.invalidateQueries({
          queryKey: [{ type: PDQueryType.WELL_INCLUDED_WELLS_KPIS }],
          ...{ exact: false },
        });
        queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.WELL_SUMMARIES }], ...{ exact: false } });
      }
    },
    [queryClient],
  );
  const requestQuery: AllWellsDataFeedQueryDto = useMemo(
    () => ({
      includeEverGreenOnly: false,
      lastWellRefreshDate: lastAllWellRefreshDate || defaultDateDto.from,
      search: searchState,
      operatorIds: operatorIds?.data
        ? operatorIds.data?.filter((operatorId) => (operatorsState ? operatorsState.includes(operatorId) : true))
        : null,
      rigIds: rigIds?.data ? rigIds.data?.filter((rigId) => (rigIdsState ? rigIdsState.includes(rigId) : true)) : null,
      from: startDateState ? dateToDateOnlyDto(startDateState) : defaultDateDto.from,
      to: endDateState ? dateToDateOnlyDto(endDateState) : defaultDateDto.to,
      formationIds: formationState ?? null,
      selectedFilters: {
        includeFlatTime: true,
        includeRotatingTime: true,
        includeSlidingTime: true,
        includeNullHoleDepth: true,
        includeAlphaRigs: true,
        includeNoneAlphaRigs: true,
        selectedWellStatusTypes: statusState,
      },
    }),
    [
      endDateState,
      formationState,
      lastAllWellRefreshDate,
      operatorIds?.data,
      operatorsState,
      rigIds?.data,
      rigIdsState,
      searchState,
      startDateState,
      statusState,
    ],
  );

  const [debouncedQuery] = useDebounce(requestQuery, 1000, {
    leading: true,
    equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b),
  });

  const { data } = useQuery<boolean>({
    queryKey: [{ uid: RequestUID.wellsDataFeed, debouncedQuery }],
    queryFn: () => {
      if (!isEnabled || !lastAllWellRefreshDate || realTimeDataState !== RealTimeDataEnum.ACTIVE)
        return new Promise((resolve) => resolve(false));
      return DataFeeds.apiDataFeedsWellsPut({
        allWellsDataFeedQueryDto: debouncedQuery,
      });
    },
    ...options,
    enabled:
      isEnabled &&
      realTimeDataState === RealTimeDataEnum.ACTIVE &&
      !!lastAllWellRefreshDate &&
      !!rigIds?.data &&
      !!operatorIds?.data,
    refetchIntervalInBackground: true,
    refetchInterval: API_REFRESH_INTERVAL,
  });
  useEffect(() => {
    if (data) refreshAllWells(data.toString());
  }, [data, refreshAllWells]);
};
