import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { ApiFiltersRigsByOperatorsPutRequest } from "apis/oag";
import { FiltersApi } from "apis/oag";
import { useAppSelector } from "reducers/store";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { dateToDateOnlyDto } from "utils/helper";
import type { PDOperatorsActiveRigs } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { useDashboardType } from "./useDashboardType";

const filters = new FiltersApi(apiConfig);

export function useOperatorsActiveRigs(
  operatorIds: number[] | null,
  filterEverGreenOnly: boolean,
  options?: Omit<UseQueryOptions<Array<number>>, "queryKey" | "queryFn">,
) {
  const { isRig } = useDashboardType();
  const rigPeriodState = useAppSelector((state) => state.allRigs.period);
  const params: ApiFiltersRigsByOperatorsPutRequest = {
    availableIdsByOperatorsQueryDto: {
      operatorIds: operatorIds ?? undefined,
      keepEvengreenOnly: filterEverGreenOnly,
      from: isRig && rigPeriodState.startDate ? dateToDateOnlyDto(rigPeriodState.startDate) : defaultDateDto.from,
      to: isRig && rigPeriodState.endDate ? dateToDateOnlyDto(rigPeriodState.endDate) : defaultDateDto.to,
    },
  };

  const OperatorsActiveRigsQueryKey: PDOperatorsActiveRigs = {
    uid: RequestUID.operatorsActiveRigs,
    type: PDQueryType.OPERATORS_ACTIVE_RIG,
    params: params,
  };

  return useQuery<Array<number>>({
    queryKey: [OperatorsActiveRigsQueryKey],
    queryFn: () => {
      return filters.apiFiltersRigsByOperatorsPut(params);
    },
    ...options,
    refetchOnMount: false,
  });
}
