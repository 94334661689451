import type { UseQueryOptions } from "@tanstack/react-query";
import { useQueryClient, useSuspenseQuery } from "@tanstack/react-query";
import type { AllRigsDataFeedQueryDto, WellStatusType } from "apis/oag";
import { DataFeedsApi } from "apis/oag";
import { RealTimeDataEnum } from "components/RealTimeIndicator";
import { useCallback, useEffect, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { useDebounce } from "use-debounce";
import { API_REFRESH_INTERVAL, apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto } from "utils/helper";
import { PDQueryType } from "utils/queryNamespaces";
import { RequestUID } from "utils/queryNamespaces";

const DataFeeds = new DataFeedsApi(apiConfig);

export const useAllRigsDataFeed = (isEnabled: boolean, options?: UseQueryOptions<boolean>) => {
  const operatorsState = useAppSelector((state) => state.allRigs.operators);
  const rigIdsState = useAppSelector((state) => state.allRigs.rigIds);

  const periodState = useAppSelector((state) => state.allRigs.period);
  const queryClient = useQueryClient();
  const lastAllRigsRefreshDate = useAppSelector((state) => state.allRigs.lastAllRigsRefreshDate);
  const realtimeData = useAppSelector((state) => state.global.realTimeData);
  const filterState = useAppSelector((state) => state.allRigs.filters);

  const requestQuery: AllRigsDataFeedQueryDto = useMemo(
    () => ({
      operatorIds: operatorsState,
      rigIds: rigIdsState,
      from: dateToDateOnlyDto(periodState?.startDate || defaultDateDto.from.utc),
      to: dateToDateOnlyDto(periodState?.endDate || defaultDateDto.to.utc),
      includeFullWellFacts: false,
      lastWellRefreshDate: lastAllRigsRefreshDate || defaultDateDto.from,
      includeAlphaRigs: !!filterState?.technology.map((tech) => tech.id).includes(RigTechnology.Alpha),
      includeNoneAlphaRigs: !!filterState?.technology.map((tech) => tech.id).includes(RigTechnology.NonAlpha),
      // TODO add
      selectedFilters: {
        directionalIntervalIds: null,
        includeFlatTime: true,
        includeSlidingTime: true,
        includeRotatingTime: true,
        includeNullHoleDepth: true,
        holeSizeIds: null,
        sectionIds: null,
        includeAlphaRigs: true,
        includeNoneAlphaRigs: true,
      },
      selectedWellStatusTypes: filterState?.wellStatus?.map((ws) => ws.id) as WellStatusType[],
    }),
    [
      filterState?.technology,
      filterState?.wellStatus,
      lastAllRigsRefreshDate,
      operatorsState,
      periodState.endDate,
      periodState.startDate,
      rigIdsState,
    ],
  );

  const refreshAllRigs = useCallback(
    (shallRefresh: string) => {
      if (shallRefresh === "true") {
        queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.RIGS_KPI }], ...{ exact: false } });
        queryClient.invalidateQueries({ queryKey: [{ type: PDQueryType.RIGS_SUMMARIES }], ...{ exact: false } });
      }
    },
    [queryClient],
  );
  const [debouncedQuery] = useDebounce(requestQuery, 1000, {
    leading: true,
    equalityFn: (a, b) => JSON.stringify(a) === JSON.stringify(b),
  });

  const data = useSuspenseQuery<boolean>({
    queryKey: [{ uid: RequestUID.wellsDataFeed, debouncedQuery }],
    queryFn: () => {
      if (!isEnabled || !lastAllRigsRefreshDate || realtimeData !== RealTimeDataEnum.ACTIVE)
        return Promise.resolve(false);
      return DataFeeds.apiDataFeedsRigsPut({
        allRigsDataFeedQueryDto: debouncedQuery,
      });
    },
    ...options,
    refetchIntervalInBackground: true,
    refetchInterval: API_REFRESH_INTERVAL,
  });
  useEffect(() => {
    if (data.data) refreshAllRigs(data.data?.toString());
  }, [data.data, refreshAllRigs]);
};
