import type { UseQueryOptions } from "@tanstack/react-query";
import { useQuery } from "@tanstack/react-query";
import type { RigLeaderboardFactListsDto, RigLeaderboardQueryDto, WellStatusType } from "apis/oag";
import { DashboardType, RigLeaderboardApi } from "apis/oag";
import { SelectedRigsContext } from "pages/AllRigs/RigList/SelectedRigsContext";
import { useContext, useMemo } from "react";
import { useAppSelector } from "reducers/store";
import { type IFiltersType, initialFilters } from "reducers/types";
import { apiConfig } from "utils/apiConfig";
import { defaultDateDto } from "utils/common";
import { RigTechnology } from "utils/enums";
import { dateToDateOnlyDto } from "utils/helper";
import type { PDRigLeaderboardQueryKey } from "utils/queryNamespaces";
import { PDQueryType, RequestUID } from "utils/queryNamespaces";

import { URL_STATE_PARAM, useStateQuery } from "./navigation/useQueryState";

const laderboardApi = new RigLeaderboardApi(apiConfig);

export function useRigLeaderboard(options?: Omit<UseQueryOptions<RigLeaderboardFactListsDto>, "queryKey" | "queryFn">) {
  const operatorsState = useAppSelector((state) => state.allRigs.operators);
  const rigIdsState = useAppSelector((state) => state.allRigs.rigIds);
  const periodState = useAppSelector((state) => state.allRigs.period);
  const filterState = useAppSelector((state) => state.allRigs.filters);

  const allWellsIncluded = useAppSelector((state) => state.allRigs.allWellsIncluded);
  const exceptions = useAppSelector((state) => state.allRigs.exceptions);

  const selectedRigsContext = useContext(SelectedRigsContext);
  const [{ operationTime }] = useStateQuery<IFiltersType>(URL_STATE_PARAM.FILTERS_WIDGET, initialFilters);

  const waitBeforeRequesting = useMemo(() => {
    return !filterState || !periodState || !allWellsIncluded;
  }, [allWellsIncluded, filterState, periodState]);

  const requestQuery: RigLeaderboardQueryDto = useMemo(() => {
    return {
      highlightedRigIds: selectedRigsContext?.selectedRigIds,
      selectedFilters: {
        sectionIds: filterState?.holeSection.map((hs) => +(hs.id || -1)),
        directionalIntervalIds: filterState?.directionalInterval.map((hs) => +(hs.id || -1)),
        phaseIds: filterState?.phases.map((p) => +(p.id || -1)),
        holeSizeIds: filterState?.holeSize.map((hs) => +(hs.id || -1)),
        includeFlatTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Flat Time"),
        includeSlidingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Sliding Time"),
        includeRotatingTime:
          operationTime === null || operationTime === undefined ? true : operationTime.includes("Rotating Time"),
        includeNullHoleDepth: true,
        includeAlphaRigs: filterState?.technology.map((tech) => tech.id).includes(RigTechnology.Alpha) || false,
        includeNoneAlphaRigs: filterState?.technology.map((tech) => tech.id).includes(RigTechnology.NonAlpha) || false,
        selectedClasses: filterState?.type.map((t) => (t.id || -1).toString()),
        selectedCountryIds: filterState?.country.map((c) => +(c.id || -1)),
        selectedHorsePowers: filterState?.horsePower.map((hp) => +(hp.id || -1)),
        selectedOperatingCenters: filterState?.operatingCenter.map((oc) => (oc.id || -1).toString()),
        selectedWellStatusTypes: filterState?.wellStatus.map((ws) => ws.id) as WellStatusType[],
      },
      dashboard: DashboardType.RigFleetPerformance,
      operatorIds: operatorsState,
      rigIds: rigIdsState,
      from: dateToDateOnlyDto(periodState?.startDate || defaultDateDto.from.utc),
      to: dateToDateOnlyDto(periodState?.endDate || defaultDateDto.to.utc),
      includeFullWellFacts: allWellsIncluded,
      useExceptions: exceptions,
    };
  }, [
    filterState,
    operationTime,
    operatorsState,
    periodState.endDate,
    periodState.startDate,
    rigIdsState,
    selectedRigsContext?.selectedRigIds,
    allWellsIncluded,
    exceptions,
  ]);

  const RigLeaderboardQueryKey: PDRigLeaderboardQueryKey = {
    uid: RequestUID.rigLeaderboard,
    params: requestQuery,
    type: PDQueryType.RIG_LEADERBOARD,
  };

  return useQuery({
    queryKey: [RigLeaderboardQueryKey, allWellsIncluded],
    queryFn: () =>
      laderboardApi.apiRigLeaderboardAllPut({
        rigLeaderboardQueryDto: requestQuery,
      }),
    ...options,
    refetchOnMount: false,
    enabled: (options?.enabled ?? true) && !waitBeforeRequesting,
  });
}
