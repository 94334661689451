/* eslint-disable react/no-multi-comp */
import { DimensionType, PivotOrderType, PivotType } from "apis/oag";
import { Text, Title } from "atoms/Typography";
import { PDComponent } from "components/PDComponents";
import { usePivotLabels } from "hooks/usePivotLabels";
import type { FactWithLabel } from "pages/AllRigs/components/LeaderboardSection/Card/interfaces";
import { useHoveredDataContext } from "pages/AllRigs/components/LeaderboardSection/helpers/HoveredDataContext";
import {
  SelectedMetric,
  useLeaderboardMetricSelectionContext,
} from "pages/AllRigs/components/LeaderboardSection/LeaderboardMetricSelectionContext";
import { isTimePivot, LABEL_TRUNCATION_LENGTH_TABLE } from "pages/AllRigs/components/LeaderboardSection/utils";
import { useSelectedRigsContext } from "pages/AllRigs/RigList/SelectedRigsContext";
import type { FC } from "react";
import { forwardRef, useMemo } from "react";
import styled from "styled-components";
import { Space } from "utils/componentLibrary";
import type { UOMHelper } from "utils/format";
import { truncateMiddleString } from "utils/helper";
import { useCustomTheme } from "utils/useTheme";

import {
  StyledBodyCell,
  StyledHeaderCell,
  StyledKpiValue,
  StyledLabelText,
  StyledTableContainer,
  StyledTableHeader,
  StyledTableRow,
  StyledTag,
  StyledTagContainer,
} from "./styled";

const LABEL_MIN_WIDTH = 100;
const LABEL_WIDTH_DIVIDED = 30;
export const hasRankSort = (order: PivotOrderType) => ([PivotOrderType.PivotValueAscending, PivotOrderType.PivotValueDescending] as PivotOrderType[]).includes(order);
export const AnonymizedData = styled.div`
  width: 40px;
  height: 14px;
  flex-grow: 0;
  border-radius: 2px;
  background-color: ${({ theme }) => theme.themeStyle.colors.primary_accent};
  align-self: center;
  margin-left: auto;
  margin-right: auto;
`;

const SingleRow: FC<{
  details: FactWithLabel;
  width: number;
  uom: UOMHelper;
  order: PivotOrderType;
  pivotType: PivotType;
}> = ({ order, details, uom, pivotType, width }) => {
  const { rank, trend, value, highlightedValue, label } = details;
  const { isDataAnonymity } = useSelectedRigsContext();
  const { setHoveredDataRank, hoveredDataRank } = useHoveredDataContext();

  const { getLabelFromKey } = usePivotLabels();
  const { atomThemeVariant } = useCustomTheme();

  const { selectedMetric } = useLeaderboardMetricSelectionContext();
  const isNetTimes = useMemo(() => selectedMetric === SelectedMetric.NetTimes, [selectedMetric]);

  const cellValue = useMemo(() => {
    return value || value === 0 ? uom.display(value, { fractionDigits: 1, unit: "" }) : "";
  }, [uom, value]);

  const cellAbbreviation = useMemo(() => {
    return uom.dimension === DimensionType.Seconds ? (isNetTimes ? "hr" : "min") : uom.abbr;
  }, [isNetTimes, uom.abbr, uom.dimension]);

  const showNonAnonymizedData = useMemo(
    () =>
      isDataAnonymity && ([PivotType.Rig, PivotType.Well, PivotType.Operator] as PivotType[]).includes(pivotType)
        ? highlightedValue
        : true,
    [highlightedValue, isDataAnonymity, pivotType],
  );

  return (
    <StyledTableRow
      $isActive={hoveredDataRank === rank}
      $isHighlighted={!!(!isTimePivot(pivotType) && highlightedValue)}
      onMouseOver={() => {
        setHoveredDataRank(rank);
      }}
      onMouseLeave={() => setHoveredDataRank(null)}
    >
      <StyledBodyCell>
        <StyledLabelText variant={atomThemeVariant}>{hasRankSort(order) ? rank : details.order}</StyledLabelText>
      </StyledBodyCell>
      <StyledBodyCell>
        {showNonAnonymizedData ? (
          <StyledLabelText variant={atomThemeVariant}>
            {truncateMiddleString(
              getLabelFromKey(label, pivotType),
              width > LABEL_MIN_WIDTH ? width / LABEL_WIDTH_DIVIDED : LABEL_TRUNCATION_LENGTH_TABLE,
            )}
          </StyledLabelText>
        ) : (
          <AnonymizedData />
        )}
      </StyledBodyCell>
      <StyledBodyCell>
        <StyledTagContainer>
          <StyledTag $positiveValue={trend >= 0}>
            {trend < 0 ? "" : "+"}
            {(trend * 100).toFixed(1)}%
          </StyledTag>
        </StyledTagContainer>
      </StyledBodyCell>
      <StyledBodyCell>
        <Space size={2}>
          <StyledKpiValue variant={atomThemeVariant}>{cellValue}</StyledKpiValue>
          <Title level={5} variant="faded">
            {cellAbbreviation}
          </Title>
        </Space>
      </StyledBodyCell>
    </StyledTableRow>
  );
};

const LeaderboardTable = forwardRef(function (
  {
    data,
    uom,
    pivotType,
    selectedMetric,
    isExpanded,
    cardId,
    order,
  }: Readonly<{
    data: FactWithLabel[];
    uom: UOMHelper;
    pivotType: PivotType;
    selectedMetric: SelectedMetric;
    isExpanded?: boolean;
    cardId: number;
    order: PivotOrderType
  }>,
  ref: React.Ref<{ scrollToIndex: (index: number) => void }>,
) {
  const customColumnLabel = useMemo(() => {
    switch (selectedMetric) {
      case SelectedMetric.NetTimes:
        return "Net Time";
      case SelectedMetric.Scores:
        return "Score";
      default:
        return "KPI";
    }
  }, [selectedMetric]);

  return (
    <StyledTableContainer $isExpanded={!!isExpanded}>
      <>
        <StyledTableHeader>
          <StyledHeaderCell>
            <Text primary="description" variant="faded" fontSize={14}>
              {([PivotOrderType.PivotValueAscending, PivotOrderType.PivotValueDescending] as PivotOrderType[]).includes(order) ? "Rank" : "Order"}
            </Text>
          </StyledHeaderCell>
          <StyledHeaderCell>
            <Text primary="description" variant="faded" fontSize={14}>
              {pivotType}
            </Text>
          </StyledHeaderCell>
          <StyledHeaderCell>
            <Text primary="description" variant="faded" fontSize={14}>
              {pivotType === PivotType.Well || pivotType === PivotType.Rig ? "Avg Comp." : "Trend"}
            </Text>
          </StyledHeaderCell>
          <StyledHeaderCell>
            <Text primary="description" variant="faded" fontSize={14}>
              {customColumnLabel}
            </Text>
          </StyledHeaderCell>
        </StyledTableHeader>

        <div style={{ height: "calc(100% - 30px)" }}>
          <PDComponent.VirtualizedList
            items={data}
            itemSizePx={24}
            scrollbarDistance={12}
            ref={ref}
            virtualizerOptions={{
              // To make sure we scroll to item in the center of the list
              scrollPaddingStart: 24 * 2,
              scrollPaddingEnd: 24 * 2,
            }}
            scrollHintProps={{ maxScrollHintSpread: 30 }}
          >
            {(item, _idx, width) => (
              <SingleRow
                order={order}
                details={item}
                uom={uom}
                width={width}
                pivotType={pivotType}
                key={`leaderboard-row-${item.rank}-${item.label}-${cardId}`}
              />
            )}
          </PDComponent.VirtualizedList>
        </div>
      </>
    </StyledTableContainer>
  );
});

export default LeaderboardTable;
