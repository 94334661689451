import { Button } from "atoms/Form";
import { PDComponent } from "components/PDComponents";
import { useActiveFormations } from "hooks/useActiveFormations";
import { useFormations } from "hooks/useFormations";
import { useOperators } from "hooks/useOperators";
import { useOperatorsActiveRigs } from "hooks/useOperatorsActiveRigs";
import { useRigActiveToWells } from "hooks/useRigActiveToWells";
import { useRigs } from "hooks/useRigs";
import { useRigsActiveOperators } from "hooks/useRigsActiveOperators";
import { useWellShortInfo } from "hooks/useWellShortInfo";
import { useCallback, useEffect, useState } from "react";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { Track } from "services/Mixpanel";
import { Popover, Tooltip } from "utils/componentLibrary";

import { StyledGap } from "./styled";

export const getCombinedFilters = <T,>({
  selectedItems,
  options,
}: {
  selectedItems: T[];
  options: T[] | undefined;
}) => {
  if (!options) return selectedItems;
  // sad that i can t use the intersection operator
  return selectedItems?.filter((item) => options.includes(item)) ?? [];
};
export const useLinkedFilters = () => {
  const dispatch = useAppDispatch();
  const operators = useOperators();
  const rigs = useRigs();
  const { data: formations } = useFormations();
  const { data: wellData } = useWellShortInfo();
  const [showFormationsPopup, setShowFormationsPopup] = useState(false);
  const formationIdsState = useAppSelector((state) => state.allRigs.formationIds);

  const updateFormations = useCallback(
    (formationIds: number[] | null) => {
      dispatch({
        type: "ALL_RIGS_FORMATION_IDS",
        payload: formationIds ? formationIds.toSorted((a, b) => a - b) : null,
      });
      return true;
    },
    [dispatch],
  );

  useEffect(() => {
    updateFormations(null);
    return () => {
      updateFormations(null);
    };
  }, [updateFormations]);

  const [showOperatorPopup, setShowOperatorPopup] = useState(false);
  const [showRigPopup, setShowRigPopup] = useState(false);
  // change name to have popup
  const [showSelectWells, setShowSelectWells] = useState(false);

  const selectedOperators = useAppSelector((state) => state.allRigs.operators);
  const selectedRigs = useAppSelector((state) => state.allRigs.rigIds);
  const selectedWells = useAppSelector((state) => state.allRigs.selectedWells);

  // consider null as all
  const selectedOperatorsValues = selectedOperators ?? operators.data?.list.map((operator) => operator.id) ?? [];
  const selectedRigsValues = selectedRigs ?? rigs.data?.list.map((rig) => rig.id) ?? [];
  const selectedWellsValues = selectedWells ?? wellData?.list.map((well) => well.id) ?? [];
  const selectedFormationsValues = formationIdsState ?? formations?.list.map((formation) => formation.id) ?? [];
  const { data: rigsFilteredBySelectedOperators } = useOperatorsActiveRigs(selectedOperatorsValues, false);
  const { data: operatorsFilteredBySelectedRigs } = useRigsActiveOperators(selectedRigsValues, false);
  const { data: wellDataFilteredBySelectedRigs } = useRigActiveToWells(selectedRigsValues);
  const { data: formationsFilteredBySelectedWells } = useActiveFormations({ wellIds: null });

  const rigsOptions = getCombinedFilters({
    selectedItems: selectedRigsValues,
    options: rigsFilteredBySelectedOperators,
  });

  const operatorsOptions = getCombinedFilters({
    selectedItems: selectedOperatorsValues,
    options: operatorsFilteredBySelectedRigs,
  });

  const wellsOptions = getCombinedFilters({
    selectedItems: selectedWellsValues,
    options: wellDataFilteredBySelectedRigs,
  });

  const formationOptions = getCombinedFilters({
    selectedItems: selectedFormationsValues,
    options: formationsFilteredBySelectedWells,
  });

  const updateOperators = useCallback(
    (operatorIds: number[] | null) => {
      dispatch({
        type: "ALL_RIGS_SET_OPERATORS",
        payload: {
          operators: operatorIds ?? null,
        },
      });
      return true;
    },
    [dispatch],
  );

  const updateRigs = useCallback(
    (rigIds: number[] | null) => {
      dispatch({
        type: "ALL_RIGS_SET_RIGS",
        payload: {
          rigIds: rigIds ?? null,
        },
      });
      return true;
    },
    [dispatch],
  );

  const onWellsChange = useCallback(
    (selectedWells: number[] | null) => {
      dispatch({
        type: "ALL_RIGS_SET_WELLS",
        payload: {
          selectedWells: selectedWells ?? null,
        },
      });

      if (!selectedWells) return;

      const rigs = [...new Set(selectedWells.map((wellId) => wellData?.byId[wellId]?.rigIds ?? []).flat())].filter(
        (rig) => {
          return rigsOptions.includes(rig);
        },
      );

      updateRigs(rigs);
    },
    [dispatch, rigsOptions, updateRigs, wellData?.byId],
  );

  const resetFilters = useCallback(() => {
    updateOperators(null);
    updateRigs(null);
    onWellsChange(null);
    updateFormations(null);
    setShowOperatorPopup(false);
    setShowRigPopup(false);
    setShowSelectWells(false);
    setShowFormationsPopup(false);
  }, [onWellsChange, updateFormations, updateOperators, updateRigs]);
  return {
    resetFilters,
    updateOperators,
    updateRigs,
    showFormationsPopup,
    setShowFormationsPopup,
    operatorsState: selectedOperatorsValues,
    rigIdsState: selectedRigsValues,
    rigIds: rigsFilteredBySelectedOperators,
    operatorIds: operatorsFilteredBySelectedRigs,
    rigOptions: rigsOptions,
    operatorOptions: operatorsOptions,
    rigs,
    operators,
    wells: wellData,
    showOperatorPopup,
    setShowOperatorPopup,
    showRigPopup,
    setShowRigPopup,
    isLoading: rigs.isLoading ?? operators.isLoading,
    formationIdsState,
    updateFormations,
    formationOptions,
    wellOptions: wellsOptions,
    selectedWells: selectedWellsValues,
    onWellsChange,
    showSelectWells,
    setShowSelectWells,
  };
};

export const LinkedSelectors = () => {
  const {
    rigs,
    rigOptions,
    operators,
    operatorOptions,
    resetFilters,
    rigIdsState,
    updateRigs,
    updateOperators,
    setShowOperatorPopup,
    setShowRigPopup,
    showOperatorPopup,
    showRigPopup,
    operatorsState,
    formationIdsState,
    updateFormations,
    formationOptions,
    showFormationsPopup,
    setShowFormationsPopup,
    wells,
    wellOptions,
    selectedWells,
    onWellsChange,
    showSelectWells,
    setShowSelectWells,
  } = useLinkedFilters();
  const { data: formations } = useFormations();
  const availableFormations =
    formations?.list.filter(
      (formation) => formationOptions.includes(formation.id) || formationIdsState?.includes(formation.id),
    ) ?? [];
  return (
    <StyledGap>
      {/* Rig Selection Controls */}
      {!rigs.isLoading && (
        <Tooltip title="Rig">
          <Popover
            content={
              <PDComponent.ComboBoxMultiSelect
                placeholder="Search Rigs"
                options={rigOptions.map((rig) => ({
                  id: rig,
                  name: rigs.data?.list.find((r) => r.id === rig)?.shortName ?? "Unknown Rig",
                }))}
                values={rigIdsState ?? []}
                onReset={() => {
                  Track.clickEvent("All rigs - Reset Rigs");

                  resetFilters();
                }}
                onChange={(e: number[]) => {
                  Track.interact("All Rigs - Rigs", {
                    Action: "Update",
                  });
                  updateRigs(e);
                  setShowRigPopup(false);
                }}
              />
            }
            trigger="click"
            placement="bottom"
            open={showRigPopup}
            onOpenChange={(e) => setShowRigPopup(e)}
            destroyTooltipOnHide
          >
            <Button
              size="large"
              icon={<PDComponent.SvgIcon name="rig" />}
              type={
                rigIdsState !== null && (rigIdsState ?? [])?.length !== rigs.data?.list.length ? "primary" : "default"
              }
              ghost={rigIdsState !== null && (rigIdsState ?? [])?.length !== rigs.data?.list.length}
            />
          </Popover>
        </Tooltip>
      )}
      <Tooltip title="Wells">
        <Popover
          content={
            <div>
              <PDComponent.ComboBoxMultiSelect
                placeholder="Search Wells"
                width={425}
                height={329}
                options={wellOptions.map((well) => ({
                  id: well,
                  name: wells?.list.find((w) => w.id === well)?.name ?? "Unknown Well",
                }))}
                values={selectedWells || []}
                onChange={(e) => {
                  Track.interact(`All rigs - Wells`, {
                    Action: "Update",
                  });
                  onWellsChange(e);
                  setShowSelectWells(false);
                }}
                onReset={() => {
                  Track.interact(`All rigs - Wells Reset`);
                  resetFilters();
                  setShowSelectWells(false);
                }}
              />
            </div>
          }
          trigger="click"
          placement="bottom"
          open={showSelectWells}
          onOpenChange={(e) => {
            setShowSelectWells(e);
          }}
          destroyTooltipOnHide
        >
          <Button
            size="large"
            icon={<PDComponent.SvgIcon name="wellInfo" />}
            type={selectedWells && (selectedWells ?? [])?.length !== wells?.list.length ? "primary" : "default"}
            ghost={!!(selectedWells && (selectedWells ?? [])?.length !== wells?.list.length)}
          />
        </Popover>
      </Tooltip>
      {/* Operator Selection Controls */}
      {!operators.isLoading && (
        <Tooltip title="Operator">
          <Popover
            content={
              <PDComponent.ComboBoxMultiSelect
                placeholder="Search"
                options={operatorOptions.map((operator) => ({
                  id: operator,
                  name: operators.data?.list.find((op) => op.id === operator)?.name ?? "Unknown Operator",
                }))}
                values={operatorsState ?? []}
                onReset={() => {
                  Track.clickEvent("All rigs - Reset Operators");
                  resetFilters();
                }}
                onChange={(e) => {
                  Track.interact("All Rigs - Operator", {
                    Action: "Update",
                  });
                  updateOperators(e);
                  setShowOperatorPopup(false);
                }}
              />
            }
            trigger="click"
            placement="bottom"
            open={showOperatorPopup}
            onOpenChange={(e) => setShowOperatorPopup(e)}
            destroyTooltipOnHide
          >
            <Button
              size="large"
              icon={<PDComponent.SvgIcon name="identification" />}
              type={
                operatorsState !== null &&
                  (operatorsState ?? operatorOptions ?? []).length !== operators.data?.list.length
                  ? "primary"
                  : "default"
              }
              ghost={(operatorsState ?? operatorOptions ?? []).length !== operators.data?.list.length}
            />
          </Popover>
        </Tooltip>
      )}

      {/* Formation Filter */}
      {availableFormations ? (
        <Tooltip title="Target Formation">
          <Popover
            content={
              <PDComponent.ComboBoxMultiSelect
                placeholder="Target Formation Name"
                options={availableFormations.map((formation) => ({
                  id: formation.id,
                  name: formation.name ?? "Unknown formation",
                }))}
                values={formationIdsState ?? availableFormations.map((formation) => formation.id)}
                onReset={() => {
                  Track.interact(`All rigs - Formations Reset`);
                  updateFormations(null);
                  resetFilters();
                  setShowFormationsPopup(false);
                }}
                onChange={(e) => {
                  updateFormations(e);
                  setShowFormationsPopup(false);
                }}
              />
            }
            trigger="click"
            placement="bottom"
            open={showFormationsPopup}
            onOpenChange={(e) => setShowFormationsPopup(e)}
            destroyTooltipOnHide
          >
            <Button
              size="large"
              icon={<PDComponent.SvgIcon name="formation" />}
              type={
                formationIdsState && (formationIdsState ?? [])?.length !== formations.list.length
                  ? "primary"
                  : "default"
              }
              ghost={!!(formationIdsState && (formationIdsState ?? [])?.length !== formations.list.length)}
            />
          </Popover>
        </Tooltip>
      ) : null}
    </StyledGap>
  );
};
