import { WellStatusType } from "apis/oag";
import { ClaraModal } from "components/Clara/ClaraModal";
import { useFiltersCategories } from "components/Filters/useFiltersCategories";
import Header from "pages/AllRigs/Header";
import { ScoreBenchmarkProvider } from "pages/RigScoreCard/ScoreBenchmarkContext";
import { useCallback, useEffect, useState } from "react";
import type { IFilterOptions, IFilterOptionValue } from "reducers/allRigsReducer";
import { useAppDispatch, useAppSelector } from "reducers/store";
import { usePageView } from "services/Mixpanel";

import { RigLeaderboardList } from "./components/RigLeaderboardList";
import { RigListContainer, RigListInner } from "./styled";

export const RigsLeaderboard = () => {
  usePageView("All Rigs Metrics");
  const [rigCount, setRigCount] = useState(0);
  const dispatch = useAppDispatch();

  const setFilters = useCallback(
    (filters: IFilterOptions | null) => {
      dispatch({ type: "ALL_RIGS_SET_FILTERS", payload: filters });
    },
    [dispatch],
  );

  const allRigsFilters = useAppSelector((state) => state.allRigs.filters);
  const { initialFilters } = useFiltersCategories(allRigsFilters, setFilters);

  useEffect(() => {
    dispatch({
      type: "ALL_RIGS_FULL_WELLS_INCLUDED",
      payload: true,
    });

    const filterOptionValue = initialFilters.wellStatus.find(
      (option: IFilterOptionValue) => option.value === WellStatusType.Completed,
    );

    if (filterOptionValue) {
      dispatch({
        type: "RESET_ALL_RIGS_FILTERS_90_DAYS",
        payload: {
          ...initialFilters,
          wellStatus: [filterOptionValue],
        },
      });
    }
    dispatch({ type: "RESET_RIG_DASHBOARD" });
  }, [dispatch, initialFilters]);

  const handleOnRigCountSettle = (totalRigs: number) => {
    setRigCount(totalRigs);
  };

  return (
    <ScoreBenchmarkProvider>
      <ClaraModal />
      <Header totalRigs={rigCount} />
      <RigListContainer>
        <RigListInner>
          <RigLeaderboardList onRigsCountSettle={handleOnRigCountSettle} />
        </RigListInner>
      </RigListContainer>
    </ScoreBenchmarkProvider>
  );
};
